import React from 'react'
import { Tab, TabList } from './styles'

interface TabProps {
  label: string
  value: string
}

interface TabListProps {
  activeTab: string
  tabs: TabProps[]
  onSelectTab: (value: string) => void
}

export const Tabs: React.FC<TabListProps> = ({
  activeTab,
  tabs,
  onSelectTab
}) => {
  const handleSelectTab = (value: string) => {
    if (value === activeTab) return

    onSelectTab(value)
  }

  return (
    <TabList>
      {tabs.map(tab => (
        <Tab
          key={tab.value}
          isActive={tab.value === activeTab}
          onClick={() => handleSelectTab(tab.value)}
        >
          {tab.label}
        </Tab>
      ))}
    </TabList>
  )
}
