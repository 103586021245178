import styled, { css } from 'styled-components'

interface Props {
  isActive?: boolean
}

export const Container = styled.div`
  margin-top: 1rem;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
`

export const Label = styled.p`
  flex-shrink: 0;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.typo.lighter};
  margin-right: 1rem;

  span {
    font-weight: 600;
    color: ${({ theme }) => theme.palette.typo.main};
  }
`

export const List = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: flex-start;
  overflow: hidden;
`

export const Item = styled.li`
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  padding: 0 1rem;
  border-radius: 3px;
  background-color: white;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.typo.lighter};
  text-decoration: none;
  /* box-shadow: 0px 3px 6px rgba($font-secondary-color, 0.1); */
  transition: all 0.3s ease-in-out;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.typo.main};
    color: white;
  }

  ${({ isActive }: Props) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.palette.typo.main};
      color: white;
      pointer-events: none;
    `}
`
