import styled from 'styled-components'

export const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
`

export const Box = styled.div`
  position: relative;
  width: fit-content;
  height: 2.625rem;
`

export const Field = styled.div`
  width: fit-content;
  min-width: 240px;
  height: 2.625rem;

  position: relative;

  padding: 0px 0px 0px 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1.5rem;

  background-color: ${({ theme }) => theme.palette.select.field.background};
  border: solid 1px;
  border-color: ${({ theme }) => theme.palette.select.field.border};
  border-radius: 8px;

  cursor: pointer;

  > span {
    font-size: 0.875rem;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.select.field.label};
  }
`

export const ArrowDown = styled.button`
  width: 2.625rem;
  height: 2.625rem;
  border: none;
  background-color: transparent;

  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;
`

export const TagList = styled.div`
  width: fit-content;
  /* max-width: 320px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5rem;

  overflow: hidden;
`

export const Tag = styled.span`
  flex-shrink: 0;
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.select.field.tag.label};

  border-radius: 6px;

  background-color: ${({ theme }) => theme.palette.select.field.tag.background};
`

export const Dropdown = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0.5rem;

  background-color: ${({ theme }) => theme.palette.select.dropdown.background};
  box-shadow: ${({ theme }) => theme.palette.select.dropdown.shadow};
  border-radius: 8px;
  top: 2.75rem;

  z-index: 500;
`

export const ListItem = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: flex-start;
  column-gap: 1rem;
  align-items: center;
  cursor: pointer;

  padding: 0.5rem;

  border-radius: 8px;
  background-color: ${({ theme }) =>
    theme.palette.select.dropdown.item.background};

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.select.dropdown.item.selected};
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;

    font-size: 0.75rem;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.select.dropdown.item.label};
  }
`

export const UncheckedBox = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border: solid 2px ${({ theme }) => theme.palette.select.field.border};
  border-radius: 8px;
`

export const CheckedBox = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${({ theme }) => theme.palette.select.field.checkbox};
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
`
