const theme = {
  name: 'main',
  palette: {
    base: {
      main: '#F3F5F9',
      overlay: 'rgba(17, 49, 90, .75)',
      line: '#dbdbdb'
    },
    scrollbar: {
      track: '#F3F5F9',
      thumb: '#CAD5E6'
    },
    typo: {
      main: '#345082',
      lighter: '#5f78a4',
      error: '#ce2d2d',
      success: '#12CB92'
    },
    modal: {
      overlay: 'rgba(9, 30, 66, 0.54)',
      lightbox: '#ffffff',
      shadow: '0px 0px 1px rgba(9, 30, 66, 0.08)',
      closeRect: '#f4f6f9',
      closeIcon: '#172b4d',
      footer: '#f8f8f8'
    },
    buttons: {
      primary: {
        default: {
          background: '#DB23C6',
          label: '#ffffff'
        },
        hover: {
          background: '#AF1B9E',
          label: '#ffffff'
        },
        disabled: {
          background: '#AFAFAF',
          label: '#D5D5D5'
        }
      },
      secondary: {
        default: {
          background: '#CACBE6',
          label: '#5A5F6E'
        },
        hover: {
          background: '#B9BAD3',
          label: '#5A5F6E'
        },
        disabled: {
          background: '#DDDDDD',
          label: '#ACACAC'
        }
      }
    },
    select: {
      field: {
        background: '#ffffff',
        border: '#BDC5CE',
        label: '#345082',
        icon: '#345082',
        tag: {
          background: '#CACBE6',
          label: '#345082'
        },
        checkbox: '#345082'
      },
      dropdown: {
        background: '#ffffff',
        shadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
        item: {
          background: 'transparent',
          label: '#345082',
          selected: '#E2E8EB'
        }
      }
    },
    tabs: {
      default: {
        background: '#E2E6ED',
        label: '#8997B1'
      },
      active: {
        background: '#ffffff',
        label: '#345082'
      }
    },
    table: {
      head: {
        background: '#CACBE6',
        label: '#345082',
        fixed: '#BBBCDB'
      },
      body: {
        background: '#F3F5F9',
        label: '#5F78A4',
        border: '#345082',
        fixed: {
          dark: '#E9ECF4',
          light: '#FCFDFF'
        }
      }
    },
    header: {
      background: 'linear-gradient(to bottom right,#0d408f,#75087b)',
      menu: {
        inactive: 'rgba(255, 255, 255, 0.5)',
        active: '#ffffff',
        highlight: '#ce23db'
      }
    }
  }
}

export default theme
