import styled from 'styled-components'

export const Toolbar = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 1rem;
`

export const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  column-gap: 1rem;
`

export const Table = styled.div`
  width: 100%;
  overflow-y: auto;

  border-top: solid 1px #dbdbdb;

  table {
    width: 100%;
    margin: 0;
    border-collapse: collapse;

    thead {
      height: 3.75rem;

      th {
        text-align: left;
        padding-left: 1rem;
      }

      span {
        display: inline-flex;
        height: 3.75rem;
        margin-top: 0.25rem;
        font-size: 0.75rem;
        font-weight: 600;
        text-transform: uppercase;

        color: ${({ theme }) => theme.palette.typo.main};
        align-items: center;
      }
    }

    tbody {
      tr {
        height: 4.75rem;
        padding-bottom: 1rem;
        /* box-shadow: 0px 3px 6px rgba($font-secondary-color, 0.1); */

        td {
          border-radius: 3px;

          span {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0px 1rem;
            height: 3.75rem;
            background-color: white;

            font-size: 0.875rem;
            font-weight: 400;
            color: ${({ theme }) => theme.palette.typo.lighter};
          }

          &:first-child {
            span {
              border-radius: 3px 0px 0px 3px;
            }
          }

          &:last-child {
            span {
              border-radius: 0px 3px 3px 0px;
            }
          }
        }
      }
    }
  }
`

export const AppliedFilters = styled.div`
  display: flex;
  column-gap: 1rem;
  padding: 1rem 0;
`

export const AppliedFilter = styled.div`
  border-radius: 3px;
  padding: 0.5rem;
  background-color: #e3eaf3;

  display: flex;
  column-gap: 1rem;
  align-items: center;

  height: 3.25rem;

  > div {
    display: flex;
    flex-direction: column;
  }
`

export const AppliedFilterLabel = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.typo.main};
  text-transform: uppercase;
`

export const AppliedFilterValue = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.typo.lighter};
`
