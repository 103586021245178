import styled from 'styled-components'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: ${({ theme }) => theme.palette.modal.overlay};

  z-index: 9000;
`

export const Container = styled.div`
  width: 100%;
  max-width: 30rem;
  height: fit-content;
  max-height: 80vh;
  border-radius: 5px;

  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  z-index: 9000;

  background-color: ${({ theme }) => theme.palette.modal.lightbox};
  box-shadow: ${({ theme }) => theme.palette.modal.shadow};
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
`

export const Body = styled.div`
  padding: 1rem 1.5rem;
  row-gap: 1rem;
  display: flex;
  flex-direction: column;
`

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 0.5rem;
  padding: 1rem;
`
