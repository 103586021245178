import React, { ChangeEvent } from 'react'
import { Typo } from '..'
import { getCurrencyMask } from '../../../util/format'
import { Container, Field } from './styles'

interface TextFieldProps {
  label?: string
  name: string
  value: string
  onChange: (value: string) => void

  full?: boolean
}

export const CurrencyField: React.FC<TextFieldProps> = ({
  label,
  name,
  value,
  onChange,
  full = false
}) => {
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = getCurrencyMask(event.target.value)
    onChange(value)
  }

  return (
    <Container>
      <Typo variant="label" htmlFor={name}>
        {label}
      </Typo>
      <Field
        onChange={handleChange}
        value={value}
        name={name}
        id={name}
        full={full}
      />
    </Container>
  )
}
