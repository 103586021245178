import React from 'react'
import { Container, ErrorMessage, Field, Label } from './styles'

interface TextFieldProps {
  label: string
  name: string
  error?: string
}

export const TextField: React.FC<TextFieldProps> = ({
  label,
  name,
  error,
  ...rest
}) => {
  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <Field>
        <input name={name} {...rest} />
      </Field>
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}
