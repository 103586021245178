import styled from 'styled-components'

export const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  cursor: pointer;
`

export const Username = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.header.menu.active};
`

export const Greeting = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.header.menu.highlight};
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.palette.base.overlay};
  z-index: 9000;
`

export const Dropdown = styled.div`
  position: absolute;
  width: 200px;
  border-radius: 8px;
  background-color: white;
  padding: 1rem;

  top: 0;
  right: 0;
  z-index: 10000;
`

export const UserArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  border-bottom: solid 1px #eaeaea;

  padding-bottom: 1rem;

  span {
    &:first-child {
      font-size: 0.875rem;
      font-weight: 600;
      color: ${({ theme }) => theme.palette.typo.main};
    }

    &:last-child {
      font-size: 0.875rem;
      font-weight: 400;
      color: ${({ theme }) => theme.palette.header.menu.highlight};
    }
  }
`

export const SignOut = styled.button`
  margin-top: 1rem;
  width: 100%;
  height: 2.625rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
  padding: 0px 1rem;
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 8px;

  transition: all 0.2s ease-in-out;

  span {
    font-size: 0.875rem;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.typo.main};
  }

  &:hover {
    background: #f8e2e2;

    span {
      color: #c92525;
    }
  }
`
