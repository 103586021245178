import React, { useState } from 'react'
import { FiEyeOff, FiEye } from 'react-icons/fi'
import { useTheme } from 'styled-components'

import { Container, ErrorMessage, Field, Label, ToggleButton } from './styles'

interface Props {
  label: string
  name: string
  error?: string
}

type PasswordField = 'password' | 'text'

export const PasswordInput: React.FC<Props> = ({
  label,
  name,
  error,
  ...rest
}) => {
  const theme = useTheme()

  const [currentType, setCurrentType] = useState<PasswordField>('password')

  function togglePasswordType() {
    setCurrentType(current => (current === 'password' ? 'text' : 'password'))
  }

  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <Field>
        <input type={currentType} name={name} {...rest} />
        <ToggleButton type="button" onClick={togglePasswordType}>
          {currentType === 'password' ? (
            <FiEye color={theme.palette.typo.main} size={18} />
          ) : (
            <FiEyeOff color={theme.palette.typo.main} size={18} />
          )}
        </ToggleButton>
      </Field>
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}
