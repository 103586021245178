import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Label = styled.label`
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.typo.main};
  margin-bottom: 0.5rem;
`

export const Field = styled.div`
  width: 100%;
  height: 2.625rem;
  margin-bottom: 0.5rem;

  position: relative;

  input {
    width: 100%;
    height: 2.625rem;
    padding: 0px 2.625rem 0px 1rem;
    border: solid 1px;
    border-color: ${({ theme }) => theme.palette.select.field.border};
    border-radius: 5px;
  }
`

export const ToggleButton = styled.button`
  width: 2.625rem;
  height: 2.625rem;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
`

export const ErrorMessage = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.typo.error};
`
