import React, { useMemo, useState } from 'react'
import { FiChevronUp, FiChevronDown, FiExternalLink } from 'react-icons/fi'
import { useTheme } from 'styled-components'

import { SearchField } from '../..'
import { Button } from '../../../../../common/components'
import { ReadingStatus } from '../../../hooks/useReportsByTeacher'

import {
  Container,
  FixedColumn,
  FixedColumnRow,
  FixedColumnRowFooter,
  Header,
  Label,
  Table,
  TableWithFixedColumns
} from '../styles'

interface ReportProps {
  data: ReadingStatus[]
}

export const ReadingStatusReport: React.FC<ReportProps> = ({ data }) => {
  const theme = useTheme()

  const [search, setSearch] = useState<string>('')
  const [column, setColumn] = useState<keyof ReadingStatus | null>(null)
  const [inverse, setInverse] = useState<boolean>(false)

  const handleExport = () => {
    const html = document.getElementById('excel-export--reading-status')
    const table = html?.outerHTML
    window.open(
      'data:application/vnd.ms-excel,' + encodeURIComponent(table as string)
    )
  }

  const handleSort = (value: keyof ReadingStatus) => {
    if (value === column) {
      setInverse(current => !current)
    } else {
      setColumn(value)
      setInverse(false)
    }
  }

  const filtered = useMemo(() => {
    if (!search) return data
    const arr =
      data.filter(
        item =>
          item.livro.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !==
          -1
      ) || []
    return arr
  }, [search, data])

  const sorted = useMemo(() => {
    if (!column) return filtered
    const arr = filtered.sort((a, b) => {
      if (a[column] < b[column]) {
        if (inverse) {
          return 1
        }
        return -1
      }

      if (a[column] > b[column]) {
        if (inverse) {
          return -1
        }
        return 1
      }

      return 0
    })
    return arr
  }, [column, inverse, search, data])

  const keys = Object.keys(data[0])

  return (
    <Container>
      <Header>
        <SearchField
          placeholder="Filtrar por livro"
          value={search}
          onChange={setSearch}
        />
        <Button variant="primary" onClick={handleExport}>
          <FiExternalLink
            color={theme.palette.buttons.primary.default.label}
            size={20}
          />
          Exportar
        </Button>
      </Header>
      <Label>
        Clique no cabeçalho da coluna desejada para ordenar a tabela
      </Label>
      <table
        id="excel-export--reading-status"
        style={{ position: 'absolute', visibility: 'hidden', opacity: 0 }}
      >
        <thead>
          <tr>
            {keys.map(item => {
              if (item === 'livro') return <th key={item}>Livro</th>
              return <th key={item}>{item}</th>
            })}
            <th>Total acessado</th>
            <th>Total concluído</th>
          </tr>
        </thead>
        <tbody>
          {sorted.map((item, index) => (
            <tr key={index}>
              {Object.entries(item).map(value => {
                const [key, val] = value
                return <td key={key}>{val}</td>
              })}
              <td>
                {Object.values(item).reduce((sum, td) => {
                  if (td === 'Acessado') {
                    return sum + 1
                  }
                  return sum
                }, 0)}
              </td>
              <td>
                {Object.values(item).reduce((sum, td) => {
                  if (td === 'Concluído') {
                    return sum + 1
                  }
                  return sum
                }, 0)}
              </td>
            </tr>
          ))}
          <tr>
            {keys.map(item => {
              if (item === 'livro') return <td key={item}>Total</td>
              return <td key={item}></td>
            })}
            <td>
              {sorted.reduce((sum, item) => {
                return (
                  sum +
                  Object.values(item).reduce((sum, td) => {
                    if (td === 'Acessado') {
                      return sum + 1
                    }
                    return sum
                  }, 0)
                )
              }, 0)}
            </td>
            <td>
              {sorted.reduce((sum, item) => {
                return (
                  sum +
                  Object.values(item).reduce((sum, td) => {
                    if (td === 'Concluído') {
                      return sum + 1
                    }
                    return sum
                  }, 0)
                )
              }, 0)}
            </td>
          </tr>
        </tbody>
      </table>

      <TableWithFixedColumns>
        <FixedColumn>
          <header
            style={{ borderRadius: '3px 0px 0px 3px' }}
            onClick={() => handleSort('livro')}
          >
            <span>
              Livro
              {column === 'livro' &&
                (inverse ? (
                  <FiChevronUp
                    size={18}
                    color={theme.palette.table.head.label}
                    style={{ marginLeft: '10px' }}
                  />
                ) : (
                  <FiChevronDown
                    size={18}
                    color={theme.palette.table.head.label}
                    style={{ marginLeft: '10px' }}
                  />
                ))}
            </span>
          </header>
          {sorted.map(item => (
            <FixedColumnRow key={item.livro}>
              <span>{item.livro}</span>
            </FixedColumnRow>
          ))}
          <FixedColumnRowFooter>
            <span>Total</span>
          </FixedColumnRowFooter>
        </FixedColumn>
        <Table squareCorners>
          <table>
            <thead>
              <tr>
                {keys.map((item, idx) => {
                  if (idx === 0) return null
                  return (
                    <th key={item} onClick={() => handleSort(item)}>
                      <span>
                        {idx === 0 ? 'Livro' : item}{' '}
                        {column === item &&
                          (inverse ? (
                            <FiChevronUp
                              size={18}
                              color={theme.palette.table.head.label}
                              style={{ marginLeft: '10px' }}
                            />
                          ) : (
                            <FiChevronDown
                              size={18}
                              color={theme.palette.table.head.label}
                              style={{ marginLeft: '10px' }}
                            />
                          ))}
                      </span>
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {sorted.map((item, index) => (
                <tr key={index}>
                  {Object.entries(item).map((value, index) => {
                    const [key, val] = value
                    if (index === 0) return null
                    return (
                      <td key={key}>
                        <span
                          className={
                            val === 'Concluído' ? '--is-completed' : undefined
                          }
                        >
                          {val}
                        </span>
                      </td>
                    )
                  })}
                </tr>
              ))}

              <tr className="total"></tr>
            </tbody>
          </table>
        </Table>
        <FixedColumn>
          <header>
            <span>Total acessado</span>
          </header>
          {sorted.map(item => (
            <FixedColumnRow key={item.livro}>
              <span>
                {Object.values(item).reduce((sum, td) => {
                  if (td === 'Acessado') {
                    return sum + 1
                  }
                  return sum
                }, 0)}
              </span>
            </FixedColumnRow>
          ))}
          <FixedColumnRowFooter>
            <span>
              {sorted.reduce((sum, item) => {
                return (
                  sum +
                  Object.values(item).reduce((sum, td) => {
                    if (td === 'Acessado') {
                      return sum + 1
                    }
                    return sum
                  }, 0)
                )
              }, 0)}
            </span>
          </FixedColumnRowFooter>
        </FixedColumn>
        <FixedColumn>
          <header style={{ borderRadius: '0px 3px 3px 0px' }}>
            <span>Total concluído</span>
          </header>
          {sorted.map(item => (
            <FixedColumnRow key={item.livro}>
              <span>
                {Object.values(item).reduce((sum, td) => {
                  if (td === 'Concluído') {
                    return sum + 1
                  }
                  return sum
                }, 0)}
              </span>
            </FixedColumnRow>
          ))}
          <FixedColumnRowFooter>
            <span>
              {sorted.reduce((sum, item) => {
                return (
                  sum +
                  Object.values(item).reduce((sum, td) => {
                    if (td === 'Concluído') {
                      return sum + 1
                    }
                    return sum
                  }, 0)
                )
              }, 0)}
            </span>
          </FixedColumnRowFooter>
        </FixedColumn>
      </TableWithFixedColumns>
    </Container>
  )
}
