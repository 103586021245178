export const getLast15days = (): { start: Date; end: Date } => {
  const now = new Date()
  const startDate = now.setDate(now.getDate() - 15)

  const start = new Date(startDate)
  const finish = new Date()

  return {
    start,
    end: finish
  }
}

export const getLast30days = (): { start: Date; end: Date } => {
  const now = new Date()
  const startDate = now.setDate(now.getDate() - 30)

  const start = new Date(startDate)
  const finish = new Date()

  return {
    start: start,
    end: finish
  }
}

export const getLastMonth = (): { start: Date; end: Date } => {
  const now = new Date()
  const today = new Date()
  let aux = now.setMonth(now.getMonth() - 1)
  aux = now.setDate(1)

  const start = new Date(aux)
  const finish = new Date(today.setDate(0))

  return {
    start: start,
    end: finish
  }
}

export const getLastThreeMonths = (): { start: Date; end: Date } => {
  const now = new Date()
  const today = new Date()
  let aux = now.setMonth(now.getMonth() - 3)
  aux = now.setDate(1)

  const start = new Date(aux)
  const finish = new Date(today.setDate(0))

  return {
    start: start,
    end: finish
  }
}

export const formatToFixed = (value: number): string => {
  return value < 10 ? `0${value}` : `${value}`
}
