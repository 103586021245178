import styled from 'styled-components'

export const Primary = styled.button`
  appearance: none;
  border: none;
  width: fit-content;
  padding: 0px 1.5rem;
  height: 2.625rem;
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.75rem;

  font-size: 0.875rem;
  font-weight: 600;

  cursor: pointer;

  color: ${({ theme }) => theme.palette.buttons.primary.default.label};

  background-color: ${({ theme }) =>
    theme.palette.buttons.primary.default.background};

  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.buttons.primary.hover.background};
    color: ${({ theme }) => theme.palette.buttons.primary.hover.label};
  }

  &:disabled {
    pointer-events: none;
    background-color: ${({ theme }) =>
      theme.palette.buttons.primary.disabled.background};
    color: ${({ theme }) => theme.palette.buttons.primary.disabled.label};
  }
`

export const Secondary = styled.button`
  appearance: none;
  border: none;
  width: fit-content;
  padding: 0px 1.5rem;
  height: 2.625rem;
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.75rem;

  font-size: 0.875rem;
  font-weight: 600;

  cursor: pointer;

  color: ${({ theme }) => theme.palette.buttons.secondary.default.label};

  background-color: ${({ theme }) =>
    theme.palette.buttons.secondary.default.background};

  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.buttons.secondary.hover.background};
    color: ${({ theme }) => theme.palette.buttons.secondary.hover.label};
  }

  &:disabled {
    pointer-events: none;
    background-color: ${({ theme }) =>
      theme.palette.buttons.secondary.disabled.background};
    color: ${({ theme }) => theme.palette.buttons.secondary.disabled.label};
  }
`

export const Modal = styled.button`
  width: 2rem;
  height: 2rem;
  appearance: none;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.modal.closeRect};
  }
`
