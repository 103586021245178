import styled, { keyframes } from 'styled-components'

const spin = keyframes`
  0% {
    border: solid 0.25rem #0d408f;
    border-bottom: solid 0.25rem transparent;
    transform: rotate(0deg)
  }
  50% {
    border: solid 0.25rem #ce23db;
    border-bottom: solid 0.25rem transparent;
    transform: rotate(720deg)
  }
  100% {
    border: solid 0.25rem #0d408f;
    border-bottom: solid 0.25rem transparent;
    transform: rotate(1440deg)
  }
`

export const SmallCircle = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: solid 0.25rem #0d408f;
  border-bottom: solid 0.25rem transparent;
  animation: ${spin} 4s linear infinite;
`

export const MediumCircle = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: solid 0.25rem #0d408f;
  border-bottom: solid 0.25rem transparent;
  animation: ${spin} 4s linear infinite;
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`
