import React, { ChangeEvent } from 'react'
import { FiSearch } from 'react-icons/fi'
import { useTheme } from 'styled-components'

import { Container, Field } from './styles'

interface SearchFieldProps {
  placeholder: string
  value: string
  onChange: (value: string) => void
}

export const SearchField: React.FC<SearchFieldProps> = ({
  placeholder,
  value,
  onChange
}) => {
  const theme = useTheme()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  return (
    <Container>
      <Field placeholder={placeholder} value={value} onChange={handleChange} />
      <FiSearch
        size={24}
        color={theme.palette.header.menu.highlight}
        style={{ position: 'absolute', left: '14px', top: '10px' }}
      />
    </Container>
  )
}
