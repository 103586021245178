import React, { useState } from 'react'
import { FiLogOut } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import useAuth from '../../../../auth/hooks/useAuth'
import { greet } from '../../../../util/greeting'

import {
  Container,
  Greeting,
  Overlay,
  Username,
  Dropdown,
  UserArea,
  SignOut
} from './styles'

export const Profile: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false)
  const navigate = useNavigate()

  const { user, handleLogout } = useAuth()

  const handleToggleDropDown = () => {
    setOpen(current => !current)
  }

  const handleReturnToLogin = () => {
    navigate('/login')
  }

  const handleSignOut = () => {
    handleLogout(handleReturnToLogin)
  }

  return (
    <>
      <Container onClick={handleToggleDropDown}>
        <Username>{user?.firstname}</Username>
        <Greeting>{greet()}</Greeting>
        {open && (
          <Dropdown>
            <UserArea>
              <span>{user?.firstname}</span>
              <span>{greet()}</span>
            </UserArea>
            <SignOut onClick={handleSignOut}>
              <FiLogOut size={22} color="#C92525" />
              <span>Sair</span>
            </SignOut>
          </Dropdown>
        )}
      </Container>
      {open && <Overlay onClick={handleToggleDropDown} />}
    </>
  )
}
