import { useState } from 'react'
import { useNavigate } from 'react-router'
import { format } from 'date-fns'

import api from '../../../api'

import { clearStorageData, getToken } from '../../../util/auth'
import axios from 'axios'

const URL = `${process.env.REACT_APP_API_URL}/v1/reports/by_teacher`

interface State {
  error: string | null
  loading: boolean
  success: boolean | null

  studentStatus: StudentStatus[] | null
  readingStatus: ReadingStatus[] | null
  rankingAccess: RankingReport[] | null
  rankingCompleted: RankingReport[] | null
}

export interface StudentStatus {
  name: string
  accessNumber: number
  booksNumber: number
  completedBooks: number
  timeInApp: string
}

export interface ReadingStatus {
  [n: string]: string
}

export interface RankingReport {
  bookName: string
  amount: number
}

interface RequestPayload {
  start: Date
  end: Date
  school: string
  schoolYear: string
  schoolClass: string
  teacher: string
  currentReport: string
}

const INITIAL_STATE = {
  error: null,
  loading: false,
  success: null,
  studentStatus: [],
  readingStatus: [{}],
  rankingAccess: [],
  rankingCompleted: []
}

const studentMapValues = (values: Array<any>) => {
  return values.map(value => ({
    name: value.name,
    accessNumber: value.access_number,
    booksNumber: value.read_books_number,
    completedBooks: value.completed_books_number,
    timeInApp: value.total_logged_time
  }))
}

const readingStatusMapValues = (values: Array<any>) => {
  const students = (students: any[]) =>
    students.reduce((acc: any, student: any) => {
      acc[student.name] =
        (student.completed && 'Concluído') || (student.read && 'Acessado') || ''
      return acc
    }, {})

  return values.map(value => ({
    livro: value.title,
    ...students(value.students)
  }))
}

const rankingMapValues = (values: Array<any>) => {
  return values.map(value => ({
    bookName: value.title,
    amount: value.quantity
  }))
}

const useReportsByTeacher = () => {
  const [state, setState] = useState<State>(INITIAL_STATE)
  const navigate = useNavigate()

  const generate = async ({
    start,
    end,
    school,
    schoolYear,
    schoolClass,
    teacher,
    currentReport
  }: RequestPayload) => {
    setState(current => ({
      ...current,
      loading: true,
      error: null,
      success: null
    }))

    const token = await getToken()

    try {
      // const { data } = await api.post(URL, payload, {
      //   headers: { Authorization: 'Bearer ' + token }
      // })

      const { data } = await axios.get(URL, {
        params: {
          initial_date: format(start, 'yyyy-MM-dd'),
          final_date: format(end, 'yyyy-MM-dd'),
          grade_id: schoolYear,
          school_id: school,
          scope: currentReport
        }
      })

      if (data.expired) {
        clearStorageData()
        navigate('/login')
      }

      let payload = {
        ...state,
        loading: false,
        error: null,
        success: true
      }

      switch (currentReport) {
        case 'student_status':
          payload = { ...payload, studentStatus: studentMapValues(data) }
          break
        case 'read_status':
          payload = { ...payload, readingStatus: readingStatusMapValues(data) }
          break
        case 'book_access_ranking':
          payload = { ...payload, rankingAccess: rankingMapValues(data) }
          break
        case 'completed_book_ranking':
          payload = { ...payload, rankingCompleted: rankingMapValues(data) }
          break
      }

      setState(payload)
    } catch (e) {
      setState({
        ...INITIAL_STATE,
        error: 'Algo de errado aconteceu ao buscar os relatórios',
        success: false
      })
    }
  }

  return { ...state, generate }
}

export default useReportsByTeacher
