import { useState } from 'react'
import { useNavigate } from 'react-router'
import { format } from 'date-fns'

import api from '../../../api'

import { clearStorageData, getToken } from '../../../util/auth'
import { PublisherSummaryType } from '../../../interfaces'

const URL = '/getPayment.php'

const INITIAL_STATE = {
  success: null,
  loading: false,
  error: null,
  publishers: null,
  totalAccess: 0,
  totalPayment: ''
}

interface HookInterface {
  success: boolean | null
  loading: boolean
  error: string | null
  publishers: PublisherSummaryType[] | null
  totalAccess: number
  totalPayment: string
}

interface UseGetPaymentCalcType {
  success: boolean | null
  loading: boolean
  error: string | null
  publishers: PublisherSummaryType[] | null
  totalAccess: number
  totalPayment: string
  query: (value: { start: Date; end: Date; value: number }) => Promise<void>
}

const useGetPaymentCalc = (): UseGetPaymentCalcType => {
  const [state, setState] = useState<HookInterface>(INITIAL_STATE)
  const navigate = useNavigate()

  const query = async ({
    start,
    end,
    value
  }: {
    start: Date
    end: Date
    value: number
  }) => {
    if (!start || !end) return
    setState(current => ({
      ...current,
      loading: true,
      success: null,
      error: null
    }))
    const token = await getToken()

    const payload = JSON.stringify({
      start: format(start, 'yyyy-MM-dd'),
      finish: format(end, 'yyyy-MM-dd'),
      value: value
    })

    try {
      const { data } = await api.post(URL, payload, {
        headers: { Authorization: 'Bearer ' + token }
      })

      if (data.expired) {
        clearStorageData()
        navigate('/login')
      }

      // eslint-disable-next-line camelcase
      const { payments_by_publisher } = data
      setState({
        success: true,
        loading: false,
        error: null,
        publishers: payments_by_publisher.publishers,
        totalAccess: payments_by_publisher.total_access,
        totalPayment: payments_by_publisher.total_payment
      })
    } catch (e) {
      setState({
        error: 'Algo de errado aconteceu ao buscar os dados',
        success: false,
        loading: false,
        publishers: null,
        totalAccess: 0,
        totalPayment: ''
      })
    }
  }

  return { ...state, query }
}

export default useGetPaymentCalc
