import React, { useEffect, useRef, useState } from 'react'
import { FiChevronDown, FiCheck } from 'react-icons/fi'
import { CSSProperties, useTheme } from 'styled-components'

import { Typo } from '..'
import {
  ArrowDown,
  Box,
  CheckedBox,
  Container,
  Dropdown,
  Field,
  ListItem,
  Tag,
  TagList,
  UncheckedBox
} from './styles'

interface OptionInterface {
  value: string
  label: string
}

interface MultiSelectProps {
  label?: string
  placeholder?: string
  options: OptionInterface[] | null | undefined
  value: string[]
  onChange: (value: string[]) => void
  style?: CSSProperties
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  label,
  placeholder,
  options,
  value,
  onChange,
  style
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState<boolean>(false)

  const theme = useTheme()

  const handleOptionClick = (item: OptionInterface) => {
    const hasItem = value.includes(item.value)

    if (hasItem) {
      const val = value.filter(itm => itm !== item.value) || []
      onChange(val)
      return
    }

    onChange([...value, item.value])
  }

  const handleDropdownClick = () => {
    setOpen(current => !current)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <Container>
      {label && <Typo variant="label">{label}</Typo>}
      <Box ref={ref}>
        <Field onClick={handleDropdownClick} style={style}>
          {!value.length &&
            (placeholder ? <span>{placeholder}</span> : <span>-</span>)}
          {!!value && (
            <TagList>
              {options?.map(item => {
                if (value.includes(item.value)) {
                  return <Tag key={item.value}>{item.label}</Tag>
                }
                return null
              })}
            </TagList>
          )}
          <ArrowDown type="button">
            <FiChevronDown size={20} color={theme.palette.typo.main} />
          </ArrowDown>
        </Field>
        {open && (
          <Dropdown>
            {!options?.length && (
              <ListItem>
                <span>Não há itens</span>
              </ListItem>
            )}
            {options?.map(item => (
              <ListItem
                key={item.label}
                onClick={() => handleOptionClick(item)}
              >
                {value.includes(item.value) ? (
                  <CheckedBox>
                    <FiCheck size={16} color="#ffffff" />
                  </CheckedBox>
                ) : (
                  <UncheckedBox />
                )}
                <span>{item.label}</span>
              </ListItem>
            ))}
          </Dropdown>
        )}
      </Box>
    </Container>
  )
}
