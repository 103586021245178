import React, { forwardRef, HTMLProps } from 'react'
import DatePicker from 'react-datepicker'

import pt from 'date-fns/locale/pt-BR'

// import 'react-datepicker/dist/react-datepicker.css'
import './react-datepicker.css'
import { Container, TextInput } from './styles'
import { getFormattedDate } from '../../../util/format'
import { Typo } from '..'

interface DatePickerProps {
  label?: string
  start: Date | null
  finish: Date | null
  onChange: (date: [Date, Date]) => void
}

type ButtonProps = HTMLProps<HTMLButtonElement>

const CustomInput = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const formattedDate = getFormattedDate(props.value as string)
  return (
    <TextInput onClick={props.onClick} ref={ref}>
      {formattedDate}
    </TextInput>
  )
})

CustomInput.displayName = 'CustomInput'

export const Datepicker: React.FC<DatePickerProps> = ({
  label,
  start,
  finish,
  onChange
}) => {
  return (
    <Container>
      {label && <Typo variant="label">{label}</Typo>}
      <DatePicker
        selected={finish}
        onChange={onChange}
        startDate={start}
        endDate={finish}
        monthsShown={2}
        // showPreviousMonths
        disabledKeyboardNavigation
        customInput={<CustomInput />}
        selectsRange
        locale={pt}
        dateFormat="yyyy-MM-dd"
        maxDate={new Date()}
      />
    </Container>
  )
}
