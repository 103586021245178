import { TOKEN } from '../constants'

export const getToken = (): string | null => {
  const user = localStorage.getItem(TOKEN)
  if (!user) return null

  const { token } = JSON.parse(user)
  return token
}

export const clearStorageData = (): void => {
  localStorage.removeItem(TOKEN)
}
