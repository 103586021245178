import { useState } from 'react'
import { useNavigate } from 'react-router'
import { format } from 'date-fns'

import api from '../../../api'

import { clearStorageData, getToken } from '../../../util/auth'
import axios from 'axios'

const URL = `${process.env.REACT_APP_API_URL}/v1/reports/by_school`

interface State {
  error: string | null
  loading: boolean
  success: boolean | null

  data: ClassStatus[] | null
}

export interface ClassStatus {
  class: string
  accessNumber: number
  booksNumber: number
  completedBooks: number
  timeInApp: string
}

interface RequestPayload {
  start: Date
  end: Date
  school: string
  schoolYear: string[]
  schoolClass: string[]
  teacher: string[]
}

const INITIAL_STATE = {
  error: null,
  loading: false,
  success: null,
  data: null
}

const mapValues = (values: Array<any>) => {
  return values.map(value => ({
    class: value.name,
    accessNumber: value.access_number,
    booksNumber: value.read_books_number,
    completedBooks: value.completed_books_number,
    timeInApp: value.total_logged_time
  }))
}

const useReportsBySchool = () => {
  const [state, setState] = useState<State>(INITIAL_STATE)
  const navigate = useNavigate()

  const generate = async ({
    start,
    end,
    school,
    schoolYear,
    schoolClass,
    teacher
  }: RequestPayload) => {
    setState(current => ({
      ...current,
      loading: true,
      error: null,
      success: null
    }))

    const token = await getToken()

    const payload = JSON.stringify({
      start: format(start, 'yyyy-MM-dd'),
      finish: format(end, 'yyyy-MM-dd')
    })

    try {
      // const { data } = await api.post(URL, payload, {
      //   headers: { Authorization: 'Bearer ' + token }
      // })

      const { data } = await axios.get(URL, {
        params: {
          initial_date: format(start, 'yyyy-MM-dd'),
          final_date: format(end, 'yyyy-MM-dd'),
          grade_ids: schoolYear,
          school_id: school,
          classroom_ids: schoolClass,
          teacher_ids: teacher
        }
      })

      if (data.expired) {
        clearStorageData()
        navigate('/login')
      }

      setState({
        loading: false,
        error: null,
        success: true,
        data: mapValues(data)
      })
    } catch (e) {
      setState({
        ...INITIAL_STATE,
        error: 'Algo de errado aconteceu ao buscar os relatórios',
        success: false
      })
    }
  }

  return { ...state, generate }
}

export default useReportsBySchool
