import React, { createContext, ReactNode, useEffect, useState } from 'react'
import api from '../../api'

const STORAGE_KEY = '@bamboleio.report:user'
export const PROFILE_STORAGE_KEY = '@bamboleio.report:profile_type'

interface UserType {
  firstname: string
  lastname: string
}

interface AuthContextType {
  user: UserType | null
  loading: boolean
  error: string | null
  handleLogin: (
    user: string,
    password: string,
    callback: VoidFunction
  ) => Promise<void>
  handleLogout: (callback: VoidFunction) => Promise<void>
  isAuthenticated: boolean
  handleLoadPriviledge: () => ProfileType
}

export type ProfileType = 'admin' | 'teacher' | 'school' | 'group' | null

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AuthContext = createContext<AuthContextType>(null!)

function AuthProvider({ children }: { children: ReactNode }) {
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false)
  const [user, setUser] = useState<UserType | null>(null)
  const [token, setToken] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  const handleAuthCheck = async () => {
    const localUser = await localStorage.getItem(STORAGE_KEY)
    if (!localUser) {
      setAuthenticated(false)
      setLoading(false)
      return
    }

    const userdata = JSON.parse(localUser)
    setToken(userdata.token)
    setUser({ firstname: userdata.firstname, lastname: userdata.lastname })
    setAuthenticated(true)
    api.defaults.headers.common.Authorization = `Bearer ${userdata.token}`
  }

  const handleLoadPriviledge = (): ProfileType => {
    const profile = localStorage.getItem(PROFILE_STORAGE_KEY)
    if (!profile) {
      return null
    }

    if (Number(profile) === 0) {
      return 'admin'
    }

    if (Number(profile) === 1) {
      return 'group'
    }

    if (Number(profile) === 2) {
      return 'school'
    }

    if (Number(profile) === 3) {
      return 'teacher'
    }

    return null
  }

  const handleLogin = async (
    user: string,
    password: string,
    callback: VoidFunction
  ) => {
    const payload = JSON.stringify({ user, password })
    setLoading(true)
    setError(null)

    try {
      const { data } = await api.post('/authenticate.php', payload)

      const { login } = data

      if (login.error) {
        setLoading(false)
        setError('Usuário ou senha incorretos')
        setAuthenticated(false)
        return
      }

      api.defaults.headers.common.Authorization = `Bearer ${login.token}`

      const storage = JSON.stringify({
        token: login.token,
        firstname: login.user_firstname,
        lastname: login.user_lastname
      })

      await localStorage.setItem(STORAGE_KEY, storage)
      await localStorage.setItem(PROFILE_STORAGE_KEY, login.profile)

      setUser({
        firstname: login.user_firstname,
        lastname: login.user_lastname
      })
      setToken(login.token)
      setAuthenticated(true)
      setError(null)
      setLoading(false)

      callback()
    } catch (e) {
      setUser(null)
      setToken(null)
      setError(
        'Ocorreu um erro ao processar a requisição. Por favor tente mais tarde'
      )
      setLoading(false)
    }
  }

  const handleLogout = async (callback: VoidFunction) => {
    setAuthenticated(false)
    await localStorage.removeItem(STORAGE_KEY)
    api.defaults.headers.common.Authorization = ''
    callback()
  }

  useEffect(() => {
    handleAuthCheck()
  }, [])

  const value = {
    user,
    loading,
    error,
    handleLogin,
    handleLogout,
    isAuthenticated,
    handleLoadPriviledge
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
