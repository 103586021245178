import React, { useEffect, useState } from 'react'

import {
  Button,
  Datepicker,
  FilterBlock,
  Select,
  Spinner,
  Typo
} from '../../../../common/components'
import { ActionBar, Body, Footer } from './styles'

import { Tabs } from '../../components'
import {
  StudentStatusReport,
  ReadingStatusReport,
  AccessRankingReport,
  ReadingRankingReport
} from '../../components/Reports'

import useReportsByTeacher, {
  RankingReport,
  ReadingStatus,
  StudentStatus
} from '../../hooks/useReportsByTeacher'

import useGetSchools from '../../hooks/useGetSchools'
import useGetSchoolYears from '../../hooks/useGetSchoolYears'
import useGetSchoolClasses from '../../hooks/useGetSchoolClasses'
import useGetTeachers from '../../hooks/useGetTeachers'

import { ProfileType } from '../../../../auth/context/AuthContext'

interface Period {
  start: Date | null
  end: Date | null
}

const INITIAL_PERIOD = {
  start: null,
  end: null
}

type Reports =
  | 'student_status'
  | 'read_status'
  | 'book_access_ranking'
  | 'completed_book_ranking'

const TABS = [
  { label: 'Status de aluno', value: 'student_status' },
  { label: 'Status de leitura', value: 'read_status' },
  { label: 'Ranking de acesso aos livros', value: 'book_access_ranking' },
  { label: 'Ranking de livros finalizados', value: 'completed_book_ranking' }
]

interface ReportInterface {
  credentials: ProfileType
}

export const Teacher: React.FC<ReportInterface> = () => {
  const [period, setPeriod] = useState<Period>(INITIAL_PERIOD)
  const [school, setSchool] = useState<string | null>(null)
  const [schoolYear, setSchoolYear] = useState<string | null>(null)
  const [schoolClass, setSchoolClass] = useState<string | null>(null)
  const [teacher, setTeacher] = useState<string | null>(null)

  const [currentReport, setCurrentReport] = useState<Reports>('student_status')

  const { loading: loadingSchools, data: schools } = useGetSchools()
  const {
    loading: loadingYears,
    data: years,
    getSchoolYears
  } = useGetSchoolYears()
  const {
    loading: loadingClasses,
    data: classes,
    getSchoolClass
  } = useGetSchoolClasses()
  const {
    loading: loadingTeachers,
    data: teachers,
    getTeachers
  } = useGetTeachers()
  const {
    loading: generating,
    error: reportError,
    success: generated,
    studentStatus,
    readingStatus,
    rankingAccess,
    rankingCompleted,
    generate
  } = useReportsByTeacher()

  const generateReport = () => {
    if (
      !period.start ||
      !period.end ||
      !school ||
      !schoolYear ||
      !schoolClass ||
      !teacher
    ) {
      return
    }
    generate({
      start: period.start,
      end: period.end,
      school,
      schoolYear,
      schoolClass,
      teacher,
      currentReport
    })
  }

  useEffect(() => {
    getSchoolYears({ school })
    setSchoolYear(null)
    setSchoolClass(null)
  }, [school])

  useEffect(() => {
    getSchoolClass({
      school,
      schoolYear
    })
    setSchoolClass(null)
  }, [school, schoolYear])

  useEffect(() => {
    getTeachers({ schoolClass })
    setTeacher(null)
  }, [schoolClass])

  useEffect(() => {
    generateReport()
  }, [currentReport])

  const handleReportSelection = (value: string) => {
    setCurrentReport(value as Reports)
  }

  const handleChangeDate = (date: [Date, Date]) => {
    setPeriod({
      start: date[0],
      end: date[1]
    })
  }

  const handleSelectSchool = (value: string) => {
    setSchool(value as string)
  }

  const handleSelectSchoolYear = (value: string) => {
    setSchoolYear(value as string)
  }

  const handleSelectSchoolClass = (value: string) => {
    setSchoolClass(value as string)
  }

  const handleSelectTeacher = (value: string) => {
    setTeacher(value as string)
  }

  const handleSubmit = () => generateReport()

  const handleResetForm = () => {
    setPeriod(INITIAL_PERIOD)
    setSchool(null)
    setSchoolYear(null)
    setSchoolClass(null)
    setTeacher(null)
  }

  const canSubmit =
    !!period.start &&
    !!period.end &&
    !!school &&
    !!schoolYear &&
    !!schoolClass &&
    !!teacher

  if (loadingSchools || loadingYears || loadingClasses || loadingTeachers) {
    return <Spinner size="full" />
  }

  return (
    <div>
      <FilterBlock heading="Por professor">
        <Body>
          <ActionBar>
            <Datepicker
              label="Período"
              start={period.start}
              finish={period.end}
              onChange={handleChangeDate}
            />
            <Select
              label="Escola"
              placeholder="Selecione uma escola"
              value={school}
              options={schools}
              onChange={handleSelectSchool}
            />
            <Select
              label="Ano escolar"
              placeholder="Selecione o ano"
              value={schoolYear}
              options={years}
              onChange={handleSelectSchoolYear}
            />
            <Select
              label="Turma"
              placeholder="Selecione a turma"
              value={schoolClass}
              options={classes}
              onChange={handleSelectSchoolClass}
            />
            <Select
              label="Professor"
              placeholder="Selecione o professor"
              value={teacher}
              options={teachers}
              onChange={handleSelectTeacher}
            />
          </ActionBar>
          <Footer>
            <Button onClick={handleResetForm}>Limpar campos</Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={!canSubmit}
            >
              Gerar relatórios
            </Button>
          </Footer>
        </Body>
      </FilterBlock>

      {generating && <Spinner size="full" />}
      {reportError && (
        <Typo variant="error">Ocorreu um erro ao gerar o relatório</Typo>
      )}
      {generated && (
        <>
          <Tabs
            activeTab={currentReport}
            onSelectTab={handleReportSelection}
            tabs={TABS}
          />
          {currentReport === 'student_status' && (
            <StudentStatusReport data={studentStatus as StudentStatus[]} />
          )}

          {currentReport === 'read_status' && (
            <ReadingStatusReport data={readingStatus as ReadingStatus[]} />
          )}

          {currentReport === 'book_access_ranking' && (
            <AccessRankingReport data={rankingAccess as RankingReport[]} />
          )}

          {currentReport === 'completed_book_ranking' && (
            <ReadingRankingReport data={rankingCompleted as RankingReport[]} />
          )}
        </>
      )}
    </div>
  )
}
