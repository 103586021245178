import styled from 'styled-components'

export const Heading = styled.h2`
  font-size: 1.5rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.typo.main};
  margin: 0;

  span {
    font-weight: 600;
  }
`

export const Label = styled.label`
  display: block;
  width: fit-content;

  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.typo.lighter};

  margin-bottom: 0.5rem;
`

export const Text = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.typo.main};

  line-height: 1.5;
  margin-bottom: 0.875rem;

  strong {
    font-weight: 600;
  }
`

export const Title = styled.h3`
  margin: 0;
  padding: 0;

  font-size: 1.125rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.typo.main};
`

export const Error = styled.span`
  display: flex;
  width: fit-content;
  align-items: center;
  column-gap: 1rem;

  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.typo.error};
  padding: 1rem 0px;

  line-height: 1.5;
  margin-bottom: 0.875rem;

  strong {
    font-weight: 600;
  }
`
