import React, { useEffect, useMemo, useState } from 'react'
import { FiFilter } from 'react-icons/fi'

import useGetList from '../../hooks/useGetList'

import {
  Button,
  Datepicker,
  Spinner,
  Typo
} from '../../../../common/components'
import { getLast15days } from '../../../../util/date'
import { VisualizationType } from '../../../../interfaces'
import {
  AppliedFilter,
  AppliedFilterLabel,
  AppliedFilters,
  AppliedFilterValue,
  Filters,
  Table,
  Toolbar
} from './styles'
import { FilterSelection, Pagination } from '../../components'
import { useTheme } from 'styled-components'

interface Period {
  start: Date
  end: Date
}

export interface FiltersProps {
  pressCompany: string
  bookTitle: string
  username: string
}

const initialFilters = {
  pressCompany: '',
  bookTitle: '',
  username: ''
}

const Visualization: React.FC = () => {
  const theme = useTheme()

  const [period, setPeriod] = useState<Period>(getLast15days())
  const [filters, setFilters] = useState<FiltersProps>(initialFilters)

  const [openFilters, setOpenFilters] = useState<boolean>(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(200)

  const { loading, data, error } = useGetList(period)

  const handleChangeDate = (date: [Date, Date]) => {
    setPeriod({
      start: date[0],
      end: date[1]
    })
  }

  const handleUpdateFilters = (value: FiltersProps) => {
    setFilters(value)
  }

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const filteredList: VisualizationType[] = useMemo(() => {
    let list = data

    if (filters.pressCompany) {
      list =
        data?.filter(
          item =>
            item.publisher
              .toLowerCase()
              .indexOf(filters.pressCompany.toLowerCase()) >= 0
        ) || []
    }

    if (filters.bookTitle) {
      list =
        list?.filter(
          item =>
            item.book_name
              .toLowerCase()
              .indexOf(filters.bookTitle.toLowerCase()) >= 0
        ) || []
    }

    if (filters.username) {
      list =
        list?.filter(
          item =>
            item.username
              .toLowerCase()
              .indexOf(filters.username.toLowerCase()) >= 0
        ) || []
    }
    return list || []
  }, [data, filters])

  useEffect(() => {
    paginate(1)
  }, [filters, period])

  const indexOfLastApp = currentPage * itemsPerPage
  const indexOfFirstApp = indexOfLastApp - itemsPerPage
  const currentList = filteredList.slice(indexOfFirstApp, indexOfLastApp)

  return (
    <>
      <div>
        <Toolbar>
          <Typo variant="heading">
            Lista de <span>visualizações</span>
          </Typo>
          <Filters>
            <Datepicker
              start={period.start}
              finish={period.end}
              onChange={handleChangeDate}
            />
            <Button onClick={() => setOpenFilters(true)}>
              <FiFilter
                size={16}
                color={theme.palette.buttons.secondary.default.label}
              />
              Filtros
            </Button>
          </Filters>
        </Toolbar>
        {(filters.bookTitle || filters.pressCompany || filters.username) && (
          <AppliedFilters>
            {filters.pressCompany && (
              <AppliedFilter>
                <div>
                  <AppliedFilterLabel>Editora</AppliedFilterLabel>
                  <AppliedFilterValue>
                    {filters.pressCompany}
                  </AppliedFilterValue>
                </div>
                <Button
                  variant="modal"
                  onClick={() =>
                    setFilters(current => ({ ...current, pressCompany: '' }))
                  }
                />
              </AppliedFilter>
            )}
            {filters.bookTitle && (
              <AppliedFilter>
                <div>
                  <AppliedFilterLabel>Livro</AppliedFilterLabel>
                  <AppliedFilterValue>
                    {filters.pressCompany}
                  </AppliedFilterValue>
                </div>
                <Button
                  variant="modal"
                  onClick={() =>
                    setFilters(current => ({ ...current, bookTitle: '' }))
                  }
                />
              </AppliedFilter>
            )}
            {filters.username && (
              <AppliedFilter>
                <div>
                  <AppliedFilterLabel>Nome de usuário</AppliedFilterLabel>
                  <AppliedFilterValue>{filters.username}</AppliedFilterValue>
                </div>
                <Button
                  variant="modal"
                  onClick={() =>
                    setFilters(current => ({ ...current, username: '' }))
                  }
                />
              </AppliedFilter>
            )}
          </AppliedFilters>
        )}
        {loading && <Spinner size="full" />}
        {!!error && <Typo variant="error">{error}</Typo>}
        {!loading && !!data && (
          <>
            <Table>
              <table>
                <thead>
                  <tr>
                    <th>
                      <span>Id</span>
                    </th>
                    <th>
                      <span>Editora</span>
                    </th>
                    <th>
                      <span>Livro</span>
                    </th>
                    <th>
                      <span>Página</span>
                    </th>
                    <th>
                      <span>Nome de usuário</span>
                    </th>
                    <th>
                      <span>data</span>
                    </th>
                    <th>
                      <span>hora</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentList.map(item => (
                    <tr key={item.id}>
                      <td>
                        <span>{item.id}</span>
                      </td>
                      <td>
                        <span>{item.publisher}</span>
                      </td>
                      <td>
                        <span>{item.book_name}</span>
                      </td>
                      <td>
                        <span>{item.page}</span>
                      </td>
                      <td>
                        <span>{item.username}</span>
                      </td>
                      <td>
                        <span>{item.date}</span>
                      </td>
                      <td>
                        <span>{item.time}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={filteredList.length}
              paginate={paginate}
              currentPage={currentPage}
            />
            {!currentList.length && (
              <p>Não há itens nesse período e com esses filtros aplicados</p>
            )}
          </>
        )}
      </div>
      {openFilters && (
        <FilterSelection
          onApply={handleUpdateFilters}
          bookTitle={filters.bookTitle}
          pressCompany={filters.pressCompany}
          username={filters.username}
          onClose={() => setOpenFilters(false)}
        />
      )}
    </>
  )
}

export default Visualization
