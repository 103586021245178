import styled from 'styled-components'

export const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
`

export const TextInput = styled.button`
  appearance: none;
  border: none;
  height: 2.625rem;
  padding: 0px 1rem;
  border: solid 1px #bdc5ce;
  border-radius: 8px;

  width: fit-content;

  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: #345082;

  background-color: #ffffff;
`
