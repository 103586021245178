import styled, { css } from 'styled-components'

interface Props {
  isMultiple?: boolean
  squareCorners?: boolean
}

export const Container = styled.div`
  width: 100%;
  padding: 2rem 1rem;

  border-radius: 5px;

  ${({ isMultiple }: Props) =>
    isMultiple &&
    css`
      border-top-left-radius: 0;
    `}

  background-color: white;
`

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Label = styled.span`
  font-size: 0.625rem;
  font-size: 600;
  color: #8c9fc1;
`

export const Table = styled.div`
  width: 100%;
  padding-top: 0.5rem;
  overflow-y: auto;

  table {
    width: 100%;
    margin: 0;
    border-collapse: collapse;
    position: relative;

    thead {
      height: 4.25rem;

      th {
        text-align: left;
        cursor: pointer;

        &:first-child {
          span {
            border-radius: 3px 0px 0px 3px;
          }
        }

        &:last-child {
          span {
            border-radius: 0px 3px 3px 0px;
          }
        }

        ${({ squareCorners }: Props) =>
          squareCorners &&
          css`
            &:first-child {
              span {
                border-radius: 0px;
              }
            }

            &:last-child {
              span {
                border-radius: 0px;
              }
            }
          `}
      }

      span {
        display: flex;
        height: 3.75rem;
        margin-top: 0.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        font-size: 0.875rem;
        font-weight: 600;
        color: ${({ theme }) => theme.palette.table.head.label};
        background-color: ${({ theme }) => theme.palette.table.head.background};

        align-items: center;
      }
    }

    tbody {
      tr {
        height: 3.125rem;

        &:nth-child(even) {
          td {
            background-color: ${({ theme }) =>
              theme.palette.table.body.background};
          }
        }

        td {
          span {
            display: flex;
            height: 3.125rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;

            font-size: 0.875rem;
            font-weight: 600;
            color: ${({ theme }) => theme.palette.table.body.label};

            &.--is-completed {
              color: ${({ theme }) => theme.palette.typo.success};
            }

            align-items: center;
          }
        }

        &.total {
          background-color: transparent;
          border-top: solid 2px
            ${({ theme }) => theme.palette.table.body.border};

          td {
            span {
              font-size: 1rem;
              color: ${({ theme }) => theme.palette.table.head.label};
            }
          }
        }
      }
    }
  }
`

export const TableWithFixedColumns = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 200px 1fr 145px 145px;
`

export const FixedColumn = styled.div`
  padding-top: 1rem;

  display: flex;
  flex-direction: column;

  header {
    width: 100%;
    height: 3.75rem;
    cursor: pointer;
    overflow: hidden;

    position: relative;

    span {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      width: 100%;
      height: 3.75rem;
      padding: 0px 1.5rem;

      background-color: ${({ theme }) => theme.palette.table.head.fixed};

      font-size: 0.875rem;
      font-weight: 600;
      color: ${({ theme }) => theme.palette.table.head.label};
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
    }
  }
`

export const FixedColumnRow = styled.div`
  height: 3.125rem;

  &:nth-of-type(even) {
    background-color: ${({ theme }) => theme.palette.table.body.fixed.dark};
  }

  &:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.palette.table.body.fixed.light};
  }

  span {
    display: flex;
    height: 3.125rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    font-size: 0.875rem;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.table.body.label};

    align-items: center;
  }
`

export const FixedColumnRowFooter = styled.div`
  height: 3.125rem;
  background-color: transparent;
  border-top: solid 2px ${({ theme }) => theme.palette.table.body.border};

  span {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    height: 3.125rem;
    padding-left: 0.875rem;
    padding-right: 0.875rem;

    font-size: 1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.table.head.label};
  }
`
