import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

interface Props {
  left: string
  count: number
}

export const Container = styled.header`
  width: 100%;
  height: 5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0px 2rem;

  background: ${({ theme }) => theme.palette.header.background};

  z-index: 9000;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`

export const Toolbar = styled.div`
  height: 5rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 3rem;
`

export const Menu = styled.div`
  position: relative;
  width: fit-content;
  height: 5rem;

  display: grid;
  grid-auto-flow: row;

  ${({ count }: Props) => css`
    grid-template-columns: repeat(${count}, 1fr);
  `};

  &::after {
    content: '';
    display: block;
    height: 2px;
    width: 2rem;
    margin-left: -1rem;
    background: ${({ theme }) => theme.palette.header.menu.highlight};

    position: absolute;
    bottom: 1.25rem;

    left: ${({ left }: Props) => left};
    transition: all 0.3s ease-in-out;
  }
`

export const MenuItem = styled(NavLink)`
  font-size: 0.875rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  padding: 0px 1rem;

  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.3s ease-in-out;

  &.active {
    color: white;
  }
`
