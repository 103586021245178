import React, { ReactNode } from 'react'
import { Error, Heading, Label, Text, Title } from './styles'

import { FiAlertCircle } from 'react-icons/fi'
import { useTheme } from 'styled-components'

interface TypoProps {
  variant: 'text' | 'title' | 'label' | 'heading' | 'error'
  children: string | ReactNode
  htmlFor?: string
}

export const Typo: React.FC<TypoProps> = ({
  variant = 'text',
  children,
  htmlFor
}) => {
  const theme = useTheme()

  if (variant === 'heading') {
    return <Heading>{children}</Heading>
  }

  if (variant === 'label') {
    return <Label htmlFor={htmlFor}>{children}</Label>
  }

  if (variant === 'title') {
    return <Title>{children}</Title>
  }

  if (variant === 'error') {
    return (
      <Error>
        <FiAlertCircle size={24} color={theme.palette.typo.error} />
        <span>{children}</span>
      </Error>
    )
  }

  return <Text>{children}</Text>
}
