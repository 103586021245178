import React, { useEffect, useRef, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { useTheme } from 'styled-components'

import { Typo } from '..'
import { ArrowDown, Box, Container, Dropdown, Field, ListItem } from './styles'

export interface OptionInterface {
  value: string
  label: string
}

interface SelectProps {
  label?: string
  placeholder?: string
  options: OptionInterface[] | null | undefined
  value: string | null
  onChange: (value: string) => void
}

export const Select: React.FC<SelectProps> = ({
  label,
  placeholder,
  options,
  value,
  onChange
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState<boolean>(false)

  const theme = useTheme()

  const handleOptionClick = (item: OptionInterface) => {
    if (!(item.value === value)) {
      console.log('enter condition')
      onChange(item.value)
      setOpen(false)
    }
  }

  const handleDropdownClick = () => {
    setOpen(current => !current)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  const currentValuelabel = options?.find(item => item.value === value)?.label

  return (
    <Container>
      {label && <Typo variant="label">{label}</Typo>}
      <Box ref={ref}>
        <Field onClick={handleDropdownClick}>
          {!value &&
            (placeholder ? <span>{placeholder}</span> : <span>-</span>)}
          {!!currentValuelabel && <span>{currentValuelabel}</span>}
          <ArrowDown type="button">
            <FiChevronDown size={20} color={theme.palette.typo.main} />
          </ArrowDown>
        </Field>
        {open && (
          <Dropdown>
            {!options?.length && (
              <ListItem>
                <span>Não há itens</span>
              </ListItem>
            )}
            {options?.map(item => (
              <ListItem
                key={item.label}
                onClick={() => handleOptionClick(item)}
              >
                <span>{item.label}</span>
              </ListItem>
            ))}
          </Dropdown>
        )}
      </Box>
    </Container>
  )
}
