import { useState } from 'react'
import { useNavigate } from 'react-router'
import { format } from 'date-fns'

import api from '../../../api'

import { clearStorageData, getToken } from '../../../util/auth'

const URL = '/exportPayment.php'

const INITIAL_STATE = {
  success: null,
  loading: false,
  error: null
}

interface HookInterface {
  success: string | null
  loading: boolean
  error: string | null
}

interface Payload {
  name: string
  email: string
  start: Date
  end: Date
  username: string
  access: string
  payment: string
  totalAccess: number
  totalPayment: string
  totalValue: string
}

interface UsePaymentType {
  success: string | null
  loading: boolean
  error: string | null
  post: (value: Payload) => Promise<void>
}

const useExportPayment = (): UsePaymentType => {
  const [state, setState] = useState<HookInterface>(INITIAL_STATE)
  const navigate = useNavigate()

  const post = async ({
    start,
    end,
    name,
    email,
    username,
    access,
    payment,
    totalAccess,
    totalPayment,
    totalValue
  }: Payload) => {
    if (!start || !end) return
    setState(current => ({
      ...current,
      loading: true,
      success: null,
      error: null
    }))
    const token = await getToken()

    const payload = JSON.stringify({
      username: username,
      email: email,
      start: format(start, 'yyyy-MM-dd'),
      finish: format(end, 'yyyy-MM-dd'),
      publisher_name: name,
      access_by_publisher: access,
      publisher_payment: 'R$ ' + payment,
      total_access: totalAccess,
      total_payment: 'R$ ' + totalPayment,
      total_value: totalValue
    })

    try {
      const { data } = await api.post(URL, payload, {
        headers: { Authorization: 'Bearer ' + token }
      })

      const { expired, message } = data

      if (expired) {
        clearStorageData()
        navigate('/login')
      }

      setState({
        success: message,
        loading: false,
        error: null
      })
    } catch (e) {
      setState({
        error: 'Algo de errado aconteceu ao buscar os dados',
        success: null,
        loading: false
      })
    }
  }

  return { ...state, post }
}

export default useExportPayment
