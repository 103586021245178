import axios from 'axios'
import { useEffect, useState } from 'react'

import { OptionInterface } from '../../../common/components'

const ENDPOINT = `${process.env.REACT_APP_API_URL}/v1/schools`

const INITIAL_STATE = {
  loading: false,
  data: null,
  error: null
}

interface State {
  loading: boolean
  error: string | null
  data: OptionInterface[] | null
}

const mapValues = (
  values: Array<{ id: string; name: string }>
): OptionInterface[] => {
  return values.map(value => ({
    label: value.name,
    value: value.id
  }))
}

const useGetSchools = (): State => {
  const [state, setState] = useState<State>(INITIAL_STATE)

  const query = async () => {
    setState(current => ({ ...current, loading: true }))
    try {
      const { data } = await axios.get(ENDPOINT)

      if (data.expired) {
        return
      }

      setState({
        data: mapValues(data),
        loading: false,
        error: null
      })
    } catch (e) {
      setState({
        ...INITIAL_STATE,
        error: 'Ocorreu um erro ao buscar as escolas disponíveis'
      })
    }
  }

  useEffect(() => {
    query()
  }, [])

  return state
}

export default useGetSchools
