import React from 'react'
import { Container, MediumCircle, SmallCircle } from './styles'

interface SpinnerProps {
  size?: 'small' | 'large' | 'full'
}

export const Spinner: React.FC<SpinnerProps> = ({ size = 'small' }) => {
  if (size === 'large') {
    return <MediumCircle />
  }

  if (size === 'full') {
    return (
      <Container>
        <MediumCircle />
      </Container>
    )
  }

  return <SmallCircle />
}
