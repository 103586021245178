import styled, { css } from 'styled-components'

interface Props {
  isActive?: boolean
}

export const TabList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  column-gap: 1rem;

  height: 3.75rem;
  width: fit-content;
  max-width: 100%;
`

export const Tab = styled.button`
  height: 3.75rem;
  padding: 0px 1.5rem;

  border: none;
  appearance: none;

  border-radius: 5px 5px 0px 0px;
  background-color: ${({ theme }) => theme.palette.tabs.default.background};

  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.tabs.default.label};

  transition: all 0.2s ease-in-out;

  ${({ isActive }: Props) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.palette.tabs.active.background};
      color: ${({ theme }) => theme.palette.tabs.active.label};
    `}
`
