import axios from 'axios'
import { useEffect, useState } from 'react'

import { OptionInterface } from '../../../common/components'

const ENDPOINT = `${process.env.REACT_APP_API_URL}/v1/grades`

const INITIAL_STATE = {
  loading: false,
  data: [],
  error: null,
  getSchoolYears: () => undefined
}

interface State {
  loading: boolean
  error: string | null
  data: OptionInterface[] | null
  getSchoolYears: any
}

interface QueryParams {
  school: string | number
}

const mapValues = (
  values: Array<{ id: string; name: string }>
): OptionInterface[] => {
  return values.map(value => ({
    label: value.name,
    value: value.id
  }))
}

const useGetSchoolYears = (): State => {
  const [state, setState] = useState<State>(INITIAL_STATE)

  const query = async (params: QueryParams) => {
    setState(current => ({ ...current, loading: true }))
    try {
      const { school } = params
      const { data } = await axios.get(ENDPOINT, {
        params: { school_id: school }
      })
      if (data.expired) {
        return
      }

      setState({
        data: mapValues(data),
        loading: false,
        error: null,
        getSchoolYears: query
      })
    } catch (e) {
      setState({
        ...INITIAL_STATE,
        error: 'Ocorreu um erro ao buscar anos escolares'
      })
    }
  }

  useEffect(() => {
    query({ school: '' })
  }, [])

  return state
}

export default useGetSchoolYears
