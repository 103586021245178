import { format, parseISO } from 'date-fns'
import pt from 'date-fns/locale/pt-BR'

export const getFormattedDate = (value: string): string => {
  if (!value) return 'Clique para começar'

  const arr = value.split(' - ')

  const formatted = arr.map(item => {
    if (item) {
      return format(parseISO(item), "d 'de' MMM 'de' yyyy", {
        locale: pt
      })
    }
    return ''
  })
  return formatted.join(' - ')
}

export const getCurrencyMask = (value: string): string => {
  value = value.replace(/\D/g, '')
  value = value.replace(/(\d)(\d{2})$/, '$1,$2')

  return value
}

export const getRawValue = (value: string): number => {
  const number =
    value.indexOf(',') !== -1 ? Number(value.replace(',', '.')) : Number(value)
  return number
}
