import React from 'react'

import { Container, Label, List, Item } from './styles'

interface PaginationProps {
  totalItems: number
  itemsPerPage: number
  currentPage: number
  paginate: (page: number) => void
}

export const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  paginate
}) => {
  const pageNumbers = []

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i)
  }

  if (pageNumbers.length < 2) return null

  const firstItem = currentPage === 1 ? 1 : itemsPerPage * (currentPage - 1) + 1
  let lastItem = currentPage === 1 ? itemsPerPage : itemsPerPage * currentPage
  if (lastItem > totalItems) lastItem = totalItems

  const lastPage = Math.ceil(totalItems / itemsPerPage)

  return (
    <Container>
      <Label>
        Exibindo <span>{firstItem}</span> a <span>{lastItem}</span> de{' '}
        <span>{totalItems}</span> resultados
      </Label>

      <List>
        <Item isActive={currentPage === 1} onClick={() => paginate(1)}>
          Primeira
        </Item>
        {currentPage > 1 ? (
          <Item onClick={() => paginate(currentPage - 1)}>Anterior</Item>
        ) : null}
        {currentPage < lastPage ? (
          <Item onClick={() => paginate(currentPage + 1)}>Próxima</Item>
        ) : null}
        <Item
          isActive={currentPage === lastPage}
          onClick={() => paginate(lastPage)}
        >
          Última
        </Item>
      </List>
    </Container>
  )
}
