import React from 'react'
import Router from './navigation/router'

import { ThemeProvider } from 'styled-components'
import GlobalStyle from './styles/global'
import theme from './styles/theme'
import { AuthProvider } from './auth/context/AuthContext'

export const App: React.FC = () => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Router />
        <GlobalStyle />
      </ThemeProvider>
    </AuthProvider>
  )
}

export default App
