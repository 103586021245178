import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  html, body {
    min-height: 100vh;
    font-family: 'Open Sans', sans-serif;
  }

  body {
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) =>
      `${theme.palette.scrollbar.thumb} ${theme.palette.scrollbar.track}`};
      background-color: ${({ theme }) => theme.palette.base.main};
  }

  body::-webkit-scrollbar {
    width: 11px;
  }

  body::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.scrollbar.track};
  }
  body::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.scrollbar.thumb};
    border-radius: 6px;
    border: 3px solid ${({ theme }) => theme.palette.scrollbar.track};
  }

  div, ul {
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) =>
      `${theme.palette.scrollbar.thumb} ${theme.palette.scrollbar.track}`};
  }

  div::-webkit-scrollbar, ul::-webkit-scrollbar {
    width: 11px;
  }

  div::-webkit-scrollbar-track, ul::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.scrollbar.track};
  }
  div::-webkit-scrollbar-thumb, ul::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.scrollbar.thumb};
    border-radius: 6px;
    border: 3px solid ${({ theme }) => theme.palette.scrollbar.track};
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    border: none;
    max-width: 100%;
    height: auto;
  }

  input, button, textarea {
    font-family: inherit;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }


  input[type=number] {
    -moz-appearance: textfield;
  }
`
