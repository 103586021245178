import { useState } from 'react'
import { useNavigate } from 'react-router'
import { format } from 'date-fns'

import api from '../../../api'

import { clearStorageData, getToken } from '../../../util/auth'
import axios from 'axios'

const URL = `${process.env.REACT_APP_API_URL}/v1/reports/by_taba`

interface State {
  error: string | null
  loading: boolean
  success: boolean | null

  schoolStatus: SchoolStatus[] | null
  classStatus: ClassStatus[] | null
  rankingAccess: RankingReport[] | null
  rankingCompleted: RankingReport[] | null
}

export interface SchoolStatus {
  name: string
  access: number
  books: number
  completed: number
  time: string
  students: number
}

export interface ClassStatus {
  class: string
  access: number
  books: number
  completed: number
  time: string
  students: number
}

export interface RankingReport {
  bookName: string
  amount: number
}

interface RequestPayload {
  start: Date
  end: Date
  currentReport: string
}

const INITIAL_STATE = {
  error: null,
  loading: false,
  success: null,
  schoolStatus: null,
  classStatus: null,
  rankingAccess: [],
  rankingCompleted: []
}

const schoolStatusMapValues = (values: Array<any>) => {
  return values.map(value => ({
    name: value.name,
    access: value.access_number,
    books: value.read_books_number,
    completed: value.completed_books_number,
    time: value.total_logged_time,
    students: value.students_number
  }))
}

const classStatusMapValues = (values: Array<any>) => {
  return values.map(value => ({
    class: value.name,
    access: value.access_number,
    books: value.read_books_number,
    completed: value.completed_books_number,
    time: value.total_logged_time,
    students: value.students_number
  }))
}

const rankingMapValues = (values: Array<any>) => {
  return values.map(value => ({
    bookName: value.title,
    amount: value.quantity
  }))
}

const useReportsByTaba = () => {
  const [state, setState] = useState<State>(INITIAL_STATE)
  const navigate = useNavigate()

  const generate = async ({ start, end, currentReport }: RequestPayload) => {
    setState(current => ({
      ...current,
      loading: true,
      error: null,
      success: null
    }))

    const token = await getToken()

    try {
      // const { data } = await api.post(URL, payload, {
      //   headers: { Authorization: 'Bearer ' + token }
      // })

      const { data } = await axios.get(URL, {
        params: {
          initial_date: format(start, 'yyyy-MM-dd'),
          final_date: format(end, 'yyyy-MM-dd'),
          scope: currentReport
        }
      })

      if (data.expired) {
        clearStorageData()
        navigate('/login')
      }

      let payload = {
        ...state,
        loading: false,
        error: null,
        success: true
      }

      switch (currentReport) {
        case 'school_status':
          payload = { ...payload, schoolStatus: schoolStatusMapValues(data) }
          break
        case 'classroom_status':
          payload = { ...payload, classStatus: classStatusMapValues(data) }
          break
        case 'book_access_ranking':
          payload = { ...payload, rankingAccess: rankingMapValues(data) }
          break
        case 'completed_book_ranking':
          payload = { ...payload, rankingCompleted: rankingMapValues(data) }
          break
      }

      setState(payload)
    } catch (e) {
      setState({
        ...INITIAL_STATE,
        error: 'Algo de errado aconteceu ao buscar os relatórios',
        success: false
      })
    }
  }

  return { ...state, generate }
}

export default useReportsByTaba
