import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'

import * as yup from 'yup'
import { useFormik } from 'formik'

import { PasswordInput, TextField } from '../../components'
import { Content, Copyright, Form, MainLogo, Page } from './styles'
import { Button, Typo } from '../../../common/components'

import { FantasticaLogo } from './FantasticaLogo'
import bamboleioLogo from './header-logo.png'

export const SignIn: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { handleLogin, error } = useAuth()

  const from: string = location.state?.from?.pathname || '/dashboard/'

  const navigateToDashboard = () => {
    navigate(from, { replace: true })
  }

  const schema = yup.object().shape({
    username: yup.string().required('Esse campo é obrigatório').trim(),
    password: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(6, 'O campo senha deve ter no mínimo 6 (seis) caracteres')
      .trim()
  })

  const { errors, touched, isValid, handleSubmit, getFieldProps } = useFormik({
    initialValues: { username: '', password: '' },
    validationSchema: schema,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: authValues => {
      handleLogin(authValues.username, authValues.password, navigateToDashboard)
    }
  })

  return (
    <Page>
      <MainLogo src={bamboleioLogo} alt="Bamboleio" />
      <Content>
        <Typo variant="heading">Entrar</Typo>

        <Form onSubmit={handleSubmit}>
          <TextField
            label="Usuário"
            {...getFieldProps('username')}
            error={errors.username && touched.username ? errors.username : ''}
          />

          <PasswordInput
            label="Senha"
            {...getFieldProps('password')}
            error={errors.password && touched.password ? errors.password : ''}
          />
          {!!error && <Typo variant="error">{error}</Typo>}

          <Button variant="primary" disabled={!isValid} type="submit">
            Entrar
          </Button>
        </Form>
      </Content>
      <Copyright>
        <span>Powered by</span>
        <FantasticaLogo />
      </Copyright>
    </Page>
  )
}
