import React, { useMemo, useState } from 'react'
import { FiChevronUp, FiChevronDown, FiExternalLink } from 'react-icons/fi'
import { useTheme } from 'styled-components'

import { SearchField } from '../..'
import { Button } from '../../../../../common/components'
import { ClassStatus } from '../../../hooks/useReportsByTaba'

import { Container, Header, Label, Table } from '../styles'

interface ReportProps {
  data: ClassStatus[]
}

export const ClassStatusTabaReport: React.FC<ReportProps> = ({ data }) => {
  const theme = useTheme()

  const [search, setSearch] = useState<string>('')
  const [column, setColumn] = useState<keyof ClassStatus | null>(null)
  const [inverse, setInverse] = useState<boolean>(false)

  const handleSort = (value: keyof ClassStatus) => {
    if (value === column) {
      setInverse(current => !current)
    } else {
      setColumn(value)
      setInverse(false)
    }
  }

  const handleExport = () => {
    const html = document.getElementById('excel-export--class-status-taba')
    const table = html?.outerHTML
    window.open(
      'data:application/vnd.ms-excel,' + encodeURIComponent(table as string)
    )
  }

  const filtered = useMemo(() => {
    if (!search) return data
    const arr =
      data.filter(
        item =>
          item.class.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !==
          -1
      ) || []
    return arr
  }, [search, data])

  const sorted = useMemo(() => {
    if (!column) return filtered
    const arr = filtered.sort((a, b) => {
      if (a[column] < b[column]) {
        if (inverse) {
          return 1
        }
        return -1
      }

      if (a[column] > b[column]) {
        if (inverse) {
          return -1
        }
        return 1
      }

      return 0
    })
    return arr
  }, [column, inverse, search, data])

  const totalAccessNumber = useMemo(() => {
    if (!filtered) return 0
    return filtered.reduce((sum, item) => sum + item.access, 0)
  }, [data, search, filtered])

  const totalBooksNumber = useMemo(() => {
    if (!filtered) return 0
    return filtered.reduce((sum, item) => sum + item.books, 0)
  }, [data, search, filtered])

  const totalCompletedBooks = useMemo(() => {
    if (!filtered) return 0
    return filtered.reduce((sum, item) => sum + item.completed, 0)
  }, [data, search, filtered])

  const totalStudents = useMemo(() => {
    if (!filtered) return 0
    return filtered.reduce((sum, item) => sum + item.students, 0)
  }, [data, search, filtered])

  return (
    <Container>
      <Header>
        <SearchField
          placeholder="Filtrar por aluno"
          value={search}
          onChange={setSearch}
        />
        <Button variant="primary" onClick={handleExport}>
          <FiExternalLink
            color={theme.palette.buttons.primary.default.label}
            size={20}
          />
          Exportar
        </Button>
      </Header>
      <Label>
        Clique no cabeçalho da coluna desejada para ordenar a tabela
      </Label>
      <Table>
        <table id="excel-export--class-status-taba">
          <thead>
            <tr>
              <th onClick={() => handleSort('class')}>
                <span>
                  Turmas{' '}
                  {column === 'class' &&
                    (inverse ? (
                      <FiChevronUp
                        size={18}
                        color={theme.palette.table.head.label}
                        style={{ marginLeft: '10px' }}
                      />
                    ) : (
                      <FiChevronDown
                        size={18}
                        color={theme.palette.table.head.label}
                        style={{ marginLeft: '10px' }}
                      />
                    ))}
                </span>
              </th>
              <th onClick={() => handleSort('access')}>
                <span>
                  Quantidade de acessos{' '}
                  {column === 'access' &&
                    (inverse ? (
                      <FiChevronUp
                        size={18}
                        color={theme.palette.table.head.label}
                        style={{ marginLeft: '10px' }}
                      />
                    ) : (
                      <FiChevronDown
                        size={18}
                        color={theme.palette.table.head.label}
                        style={{ marginLeft: '10px' }}
                      />
                    ))}
                </span>
              </th>
              <th onClick={() => handleSort('books')}>
                <span>
                  Quantidade de livros acessados{' '}
                  {column === 'books' &&
                    (inverse ? (
                      <FiChevronUp
                        size={18}
                        color={theme.palette.table.head.label}
                        style={{ marginLeft: '10px' }}
                      />
                    ) : (
                      <FiChevronDown
                        size={18}
                        color={theme.palette.table.head.label}
                        style={{ marginLeft: '10px' }}
                      />
                    ))}
                </span>
              </th>
              <th onClick={() => handleSort('completed')}>
                <span>
                  Quantidade de livros finalizados{' '}
                  {column === 'completed' &&
                    (inverse ? (
                      <FiChevronUp
                        size={18}
                        color={theme.palette.table.head.label}
                        style={{ marginLeft: '10px' }}
                      />
                    ) : (
                      <FiChevronDown
                        size={18}
                        color={theme.palette.table.head.label}
                        style={{ marginLeft: '10px' }}
                      />
                    ))}
                </span>
              </th>
              <th onClick={() => handleSort('time')}>
                <span>
                  Tempo na ferramenta{' '}
                  {column === 'time' &&
                    (inverse ? (
                      <FiChevronUp
                        size={18}
                        color={theme.palette.table.head.label}
                        style={{ marginLeft: '10px' }}
                      />
                    ) : (
                      <FiChevronDown
                        size={18}
                        color={theme.palette.table.head.label}
                        style={{ marginLeft: '10px' }}
                      />
                    ))}
                </span>
              </th>
              <th onClick={() => handleSort('students')}>
                <span>
                  Quantidade de alunos{' '}
                  {column === 'students' &&
                    (inverse ? (
                      <FiChevronUp
                        size={18}
                        color={theme.palette.table.head.label}
                        style={{ marginLeft: '10px' }}
                      />
                    ) : (
                      <FiChevronDown
                        size={18}
                        color={theme.palette.table.head.label}
                        style={{ marginLeft: '10px' }}
                      />
                    ))}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {sorted?.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>{item.class}</span>
                </td>
                <td>
                  <span>{item.access}</span>
                </td>
                <td>
                  <span>{item.books}</span>
                </td>
                <td>
                  <span>{item.completed}</span>
                </td>
                <td>
                  <span>{item.time}</span>
                </td>
                <td>
                  <span>{item.students}</span>
                </td>
              </tr>
            ))}
            <tr className="total">
              <td>
                <span>Total geral</span>
              </td>
              <td>
                <span>{totalAccessNumber}</span>
              </td>
              <td>
                <span>{totalBooksNumber}</span>
              </td>
              <td>
                <span>{totalCompletedBooks}</span>
              </td>
              <td></td>
              <td>
                <span>{totalStudents}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </Table>
    </Container>
  )
}
