import styled from 'styled-components'

import background from './assets/page-bg.png'

export const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;

  /* display: grid;
  grid-template-rows: 5rem 1fr; */

  background-color: ${({ theme }) => theme.palette.base.main};
`

export const Body = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;

  padding: 7rem 2rem 11rem;

  overflow-y: auto;
`

export const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 11rem;

  background-image: url(${background});
  background-position: 32px bottom;
  background-size: contain;
  background-repeat: no-repeat;
`
