import React, { ReactNode, useState } from 'react'
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi'
import { useTheme } from 'styled-components'

import { Typo } from '..'
import { CollapseButton, Container, Header } from './styles'

interface FilterBlockProps {
  heading: string
  children: ReactNode
}

export const FilterBlock: React.FC<FilterBlockProps> = ({
  heading,
  children
}) => {
  const theme = useTheme()

  const [collapsed, setCollapsed] = useState<boolean>(false)

  const handleToggleCollapse = () => {
    setCollapsed(current => !current)
  }

  return (
    <Container>
      <Header>
        <Typo variant="title">{heading}</Typo>
        <CollapseButton type="button" onClick={handleToggleCollapse}>
          {collapsed ? (
            <>
              <span>Expandir</span>
              <FiMaximize2 size={16} color={theme.palette.typo.lighter} />
            </>
          ) : (
            <>
              <span>Minimizar</span>
              <FiMinimize2 size={16} color={theme.palette.typo.lighter} />
            </>
          )}
        </CollapseButton>
      </Header>
      {!collapsed && <div>{children}</div>}
    </Container>
  )
}
