import axios from 'axios'
import { useEffect, useState } from 'react'

import { OptionInterface } from '../../../common/components'

const ENDPOINT = `${process.env.REACT_APP_API_URL}/v1/teachers`

const INITIAL_STATE = {
  loading: false,
  data: null,
  error: null,
  getTeachers: () => undefined
}

interface State {
  loading: boolean
  error: string | null
  data: OptionInterface[] | null
  getTeachers: any
}

interface QueryParams {
  schoolClass: string | number
}

const mapValues = (
  values: Array<{ id: string; name: string }>
): OptionInterface[] => {
  return values.map(value => ({
    label: value.name,
    value: value.id
  }))
}

const useGetTeachers = (): State => {
  const [state, setState] = useState<State>(INITIAL_STATE)

  const query = async (params: QueryParams) => {
    setState(current => ({ ...current, loading: true }))
    try {
      const { schoolClass } = params
      const { data } = await axios.get(ENDPOINT, {
        params: { classroom_id: schoolClass }
      })
      if (data.expired) {
        return
      }

      setState({
        data: mapValues(data),
        loading: false,
        error: null,
        getTeachers: query
      })
    } catch (e) {
      setState({
        ...INITIAL_STATE,
        error: 'Ocorreu um erro ao buscar os(as) professores(as)'
      })
    }
  }

  useEffect(() => {
    query({ schoolClass: '' })
  }, [])

  return state
}

export default useGetTeachers
