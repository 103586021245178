import styled, { css } from 'styled-components'

interface Props {
  full?: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Field = styled.input`
  height: 2.625rem;
  padding: 0px 1rem;
  border: solid 1px;
  border-color: #bdc5ce;
  border-radius: 8px;

  width: fit-content;
  ${({ full }: Props) =>
    full &&
    css`
      width: 100%;
    `};

  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: #345082;

  background-color: #ffffff;
`
