import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import { FiMail } from 'react-icons/fi'

import useGetPaymentCalc from '../../hooks/useGetPaymentCalc'
import useAuth from '../../../../auth/hooks/useAuth'

import {
  Button,
  CurrencyField,
  Datepicker,
  Spinner,
  Typo
} from '../../../../common/components'

import { getRawValue } from '../../../../util/format'

import { Calculation, Footer, Header, PublisherItem, Toolbar } from './styles'
import { format } from 'date-fns'
import { MailDialog } from '../../components'

interface Period {
  start: Date | null
  end: Date | null
}

interface Publisher {
  name: string
  access: string
  payment: string
  email: string

  totalAccess: number
  totalPayment: string
  totalValue: string
}

const Payment: React.FC = () => {
  const theme = useTheme()
  const { user } = useAuth()

  const [period, setPeriod] = useState<Period>({ start: null, end: null })
  const [value, setValue] = useState<string>('')

  const [selectedPublisher, setSelectedPublisher] = useState<Publisher | null>(
    null
  )

  const canSubmit = !!period.start && !!period.end && !!value

  const {
    loading,
    query,
    error,
    success,
    publishers,
    totalAccess,
    totalPayment
  } = useGetPaymentCalc()

  const handleChangeDate = (date: [Date, Date]) => {
    setPeriod({
      start: date[0],
      end: date[1]
    })
  }

  const handleSubmitData = () => {
    if (!period.start || !period.end) return
    const total = getRawValue(value)
    query({ start: period.start, end: period.end, value: total })
  }

  return (
    <>
      <div>
        <Typo variant="heading">
          Cálculo de <span>repasse para os parceiros editoriais</span>
        </Typo>
        <Toolbar>
          <Datepicker
            label="Período"
            start={period.start}
            finish={period.end}
            onChange={handleChangeDate}
          />
          <CurrencyField
            label="Valor"
            name="totalValue"
            value={value}
            onChange={setValue}
          />
          <Button
            variant="primary"
            onClick={handleSubmitData}
            disabled={!canSubmit}
          >
            Calcular
          </Button>
        </Toolbar>

        {loading && <Spinner size="full" />}
        {!!error && <Typo variant="error">{error}</Typo>}
        {success && (
          <Calculation>
            <Header>
              <div>
                <Typo variant="label">Consulta executada por</Typo>
                <Typo variant="title">{`${user?.firstname} ${user?.lastname}`}</Typo>
              </div>
              <div>
                <Typo variant="label">Período</Typo>
                <Typo variant="title">{`De ${format(
                  period.start as Date,
                  'dd/MM/yyyy'
                )} até ${format(period.end as Date, 'dd/MM/yyyy')}`}</Typo>
              </div>
            </Header>
            {publishers?.map(item => (
              <PublisherItem key={item.id}>
                <div>
                  <div>
                    <Typo variant="label">Editora</Typo>
                    <Typo variant="title">{item.publisher}</Typo>
                  </div>
                  <div>
                    <Typo variant="label">Acessos</Typo>
                    <Typo variant="title">{item.access_by_publisher}</Typo>
                  </div>
                  <div>
                    <Typo variant="label">Valor do repasse</Typo>
                    <Typo variant="title">R$ {item.publisher_payment}</Typo>
                  </div>
                </div>

                <Button
                  onClick={() =>
                    setSelectedPublisher({
                      access: item.access_by_publisher,
                      email: item.email,
                      name: item.publisher,
                      payment: item.publisher_payment,
                      totalAccess: totalAccess,
                      totalPayment: totalPayment,
                      totalValue: value
                    })
                  }
                >
                  <FiMail
                    size={16}
                    color={theme.palette.buttons.secondary.default.label}
                  />
                  Enviar por email
                </Button>
              </PublisherItem>
            ))}
            <Footer>
              <div>
                <Typo variant="label">Total de acessos</Typo>
                <Typo variant="title">{totalAccess}</Typo>
              </div>
              <div>
                <Typo variant="label">Total de repasse</Typo>
                <Typo variant="title">R$ {totalPayment}</Typo>
              </div>
            </Footer>
          </Calculation>
        )}
      </div>
      {!!selectedPublisher && (
        <MailDialog
          onCancel={() => setSelectedPublisher(null)}
          {...selectedPublisher}
          start={period.start as Date}
          end={period.end as Date}
          username={`${user?.firstname} ${user?.lastname}`}
        />
      )}
    </>
  )
}

export default Payment
