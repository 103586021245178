import React, { ChangeEvent, useState } from 'react'
import { Button, TextField, Typo } from '../../../../common/components'
import { FiltersProps } from '../../pages/Visualization/Visualization'

import { Overlay, Container, Header, Body, Footer } from './styles'

interface FilterProps {
  pressCompany: string
  bookTitle: string
  username: string
  onApply: (value: FiltersProps) => void
  onClose: () => void
}

export const FilterSelection: React.FC<FilterProps> = ({
  pressCompany,
  bookTitle,
  username,
  onApply,
  onClose
}) => {
  const [filters, setFilters] = useState<FiltersProps>({
    pressCompany,
    bookTitle,
    username
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilters(current => ({
      ...current,
      [event.target.name]: event.target.value
    }))
  }

  const handleApplyFilters = () => {
    onApply(filters)
    onClose()
  }

  const hasChanges =
    filters.bookTitle !== bookTitle ||
    filters.pressCompany !== pressCompany ||
    filters.username !== username

  return (
    <>
      <Overlay />
      <Container>
        <Header>
          <Typo variant="title">Filtrar</Typo>
          <Button variant="modal" onClick={onClose} />
        </Header>
        <Body>
          <TextField
            label="Editora"
            name="pressCompany"
            value={filters.pressCompany}
            onChange={handleChange}
            full
          />
          <TextField
            label="Livro"
            name="bookTitle"
            value={filters.bookTitle}
            onChange={handleChange}
            full
          />
          <TextField
            label="Nome de usuário"
            name="username"
            value={filters.username}
            onChange={handleChange}
            full
          />
        </Body>
        <Footer>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            variant="primary"
            disabled={!hasChanges}
            onClick={handleApplyFilters}
          >
            Aplicar filtros
          </Button>
        </Footer>
      </Container>
    </>
  )
}
