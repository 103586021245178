import styled from 'styled-components'

export const Body = styled.div`
  width: 100%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
`

export const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  row-gap: 2rem;
  flex-wrap: wrap;

  > * {
    flex-shrink: 0;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 0.75rem;
`
