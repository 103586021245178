import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { format } from 'date-fns'

import api from '../../../api'

import { clearStorageData, getToken } from '../../../util/auth'
import { VisualizationType } from '../../../interfaces'

const URL = '/getAccess.php'

const INITIAL_STATE = {
  loading: false,
  data: null,
  error: null
}

interface HookInterface {
  loading: boolean
  error: string | null
  data: VisualizationType[] | null
}

const useGetList = ({
  start,
  end
}: {
  start: Date
  end: Date
}): HookInterface => {
  const [state, setState] = useState<HookInterface>(INITIAL_STATE)
  const navigate = useNavigate()

  const query = async () => {
    if (!start && !end) return
    setState(current => ({ ...current, loading: true }))
    const token = await getToken()

    const payload = JSON.stringify({
      start: format(start, 'yyyy-MM-dd'),
      finish: format(end, 'yyyy-MM-dd')
    })
    try {
      const { data } = await api.post(URL, payload, {
        headers: { Authorization: 'Bearer ' + token }
      })

      if (data.expired) {
        clearStorageData()
        navigate('/login')
      }

      // eslint-disable-next-line camelcase
      const { access_book } = data
      setState({ data: access_book, loading: false, error: null })
    } catch (e) {
      setState({
        error: 'Algo de errado aconteceu ao buscar os dados',
        loading: false,
        data: null
      })
    }
  }

  useEffect(() => {
    query()
  }, [start, end])

  return state
}

export default useGetList
