import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  border-radius: 12px;
  background-color: white;
  margin-bottom: 2rem;

  transition: all 0.3s ease-in-out;
`

export const Header = styled.header`
  width: 100%;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CollapseButton = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;

  display: flex;
  column-gap: 1rem;

  cursor: pointer;

  span {
    color: ${({ theme }) => theme.palette.typo.lighter};
    font-size: 0.75rem;
  }
`

export const FilterBody = styled.div`
  width: 100%;
  padding: 0px 1.5rem 1.5rem;
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
`
