import React, { ReactNode } from 'react'
import { Modal, Primary, Secondary } from './styles'

import { FiX } from 'react-icons/fi'
import { useTheme } from 'styled-components'

interface ButtonProps {
  variant?: 'primary' | 'secondary' | 'modal'
  onClick?: () => void
  children?: string | ReactNode
  disabled?: boolean
  type?: 'submit' | 'reset' | 'button'
}

export const Button: React.FC<ButtonProps> = ({
  variant = 'secondary',
  onClick,
  children,
  disabled = false,
  type = 'button'
}) => {
  const theme = useTheme()

  if (variant === 'primary') {
    return (
      <Primary disabled={disabled} onClick={onClick} type={type}>
        {children}
      </Primary>
    )
  }

  if (variant === 'modal') {
    return (
      <Modal onClick={onClick} type={type}>
        <FiX size={20} color={theme.palette.modal.closeIcon} />
      </Modal>
    )
  }

  return (
    <Secondary disabled={disabled} type={type} onClick={onClick}>
      {children}
    </Secondary>
  )
}
