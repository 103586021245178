import styled from 'styled-components'

export const Page = styled.div`
  width: 100%;
  min-height: 100vh;
  /* background-color: ${({ theme }) => theme.palette.base.main}; */
  background: ${({ theme }) => theme.palette.header.background};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 2rem;
`

export const Content = styled.div`
  width: 100%;
  max-width: 480px;

  border-radius: 12px;
  background-color: white;
  padding: 2rem;
`

export const Form = styled.form`
  width: 100%;
  margin: 2rem 0;

  display: flex;
  flex-direction: column;

  row-gap: 1rem;
`

export const Copyright = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 100px;

  span {
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.75);
    margin-bottom: 1rem;
  }
`

export const MainLogo = styled.img`
  width: 150px;
  height: auto;
  margin-bottom: 2rem;
`
