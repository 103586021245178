import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { Dashboard, SignIn } from '../auth/pages'
import { AuthMiddleware } from '../auth/pages/RequirePath'

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<SignIn />} />
        <Route
          path="dashboard/*"
          element={
            <AuthMiddleware>
              <Dashboard />
            </AuthMiddleware>
          }
        />
        <Route path="/" element={<Navigate to="dashboard" />} />
        <Route path="*" element={<Navigate to="dashboard" />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
