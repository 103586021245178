import React, { useEffect, useState } from 'react'

import {
  Button,
  Datepicker,
  FilterBlock,
  MultiSelect,
  Select,
  Spinner,
  Typo
} from '../../../../common/components'

import { ActionBar, Body, Footer } from './styles'

import { ClassStatusReport } from '../../components/Reports'

import useReportsBySchool, { ClassStatus } from '../../hooks/useReportsBySchool'

import useGetSchools from '../../hooks/useGetSchools'
import useGetSchoolYears from '../../hooks/useGetSchoolYears'
import useGetSchoolClasses from '../../hooks/useGetSchoolClasses'
import useGetTeachers from '../../hooks/useGetTeachers'
import { ProfileType } from '../../../../auth/context/AuthContext'
import useAuth from '../../../../auth/hooks/useAuth'

interface Period {
  start: Date | null
  end: Date | null
}

const INITIAL_PERIOD = {
  start: null,
  end: null
}

interface Priviledge {
  loading: boolean
  profile: ProfileType
}

const INITIAL_STATUS = {
  loading: true,
  profile: null
}

export const School: React.FC = () => {
  const [priviledge, setPriviledge] = useState<Priviledge>(INITIAL_STATUS)

  const { handleLoadPriviledge } = useAuth()

  const [period, setPeriod] = useState<Period>(INITIAL_PERIOD)
  const [school, setSchool] = useState<string | null>(null)
  const [schoolYear, setSchoolYear] = useState<string[]>([])
  const [schoolClass, setSchoolClass] = useState<string[]>([])
  const [teacher, setTeacher] = useState<string[]>([])

  const { loading: loadingSchools, data: schools } = useGetSchools()
  const {
    loading: loadingYears,
    data: years,
    getSchoolYears
  } = useGetSchoolYears()
  const {
    loading: loadingClasses,
    data: classes,
    getSchoolClass
  } = useGetSchoolClasses()
  const {
    loading: loadingTeachers,
    data: teachers,
    getTeachers
  } = useGetTeachers()
  const {
    loading: generating,
    error: reportError,
    success: generated,
    data,
    generate
  } = useReportsBySchool()

  useEffect(() => {
    getSchoolYears({ school: school ? school.toString() : '' })
    setSchoolYear([])
    setSchoolClass([])
  }, [school])

  useEffect(() => {
    getSchoolClass({
      school: school || null,
      schoolYear: schoolYear || null
    })
    setSchoolClass([])
  }, [school, schoolYear])

  useEffect(() => {
    getTeachers({ schoolClass: schoolClass || null })
    setTeacher([])
  }, [schoolClass])

  const handleChangeDate = (date: [Date, Date]) => {
    setPeriod({
      start: date[0],
      end: date[1]
    })
  }

  const handleSelectSchool = (value: string | number | boolean) => {
    setSchool(value as string)
    getSchoolYears({ school: value ? value.toString() : '' })
  }

  const handleSelectSchoolYear = (value: string[]) => {
    setSchoolYear(value)
  }

  const handleSelectSchoolClass = (value: string[]) => {
    setSchoolClass(value)
  }

  const handleSelectTeacher = (value: string[]) => {
    setTeacher(value)
  }

  const handleSubmit = () => {
    if (
      !period.start ||
      !period.end ||
      !school ||
      !schoolYear ||
      !schoolClass ||
      !teacher
    ) {
      return
    }
    generate({
      start: period.start,
      end: period.end,
      school,
      schoolYear,
      schoolClass,
      teacher
    })
  }

  const handleResetForm = () => {
    setPeriod(INITIAL_PERIOD)
    setSchool(null)
    setSchoolYear([])
    setSchoolClass([])
    setTeacher([])
  }

  const canSubmit =
    !!period.start &&
    !!period.end &&
    !!school &&
    !!schoolYear &&
    !!schoolClass &&
    !!teacher

  useEffect(() => {
    const credentials = handleLoadPriviledge()
    setPriviledge({
      loading: false,
      profile: credentials
    })
  }, [])

  if (
    priviledge.loading ||
    loadingSchools ||
    loadingYears ||
    loadingClasses ||
    loadingTeachers
  ) {
    return <Spinner size="full" />
  }

  return (
    <div>
      <FilterBlock heading="Por escola">
        <Body>
          <ActionBar>
            <Datepicker
              label="Período"
              start={period.start}
              finish={period.end}
              onChange={handleChangeDate}
            />
            <Select
              label="Escola"
              placeholder="Selecione uma escola"
              value={school}
              options={schools}
              onChange={handleSelectSchool}
            />
            <MultiSelect
              label="Ano escolar"
              placeholder="Selecione o ano"
              value={schoolYear}
              options={years}
              onChange={handleSelectSchoolYear}
              style={{ maxWidth: '480px' }}
            />
            <MultiSelect
              label="Turma"
              placeholder="Selecione a turma"
              value={schoolClass}
              options={classes}
              onChange={handleSelectSchoolClass}
              style={{ maxWidth: '480px' }}
            />
            <MultiSelect
              label="Professor"
              placeholder="Selecione o professor"
              value={teacher}
              options={teachers}
              onChange={handleSelectTeacher}
              style={{ maxWidth: '480px' }}
            />
          </ActionBar>
          <Footer>
            <Button onClick={handleResetForm}>Limpar campos</Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={!canSubmit}
            >
              Gerar relatórios
            </Button>
          </Footer>
        </Body>
      </FilterBlock>

      {generating && <Spinner size="full" />}
      {reportError && (
        <Typo variant="error">Ocorreu um erro ao gerar o relatório</Typo>
      )}
      {generated && <ClassStatusReport data={data as ClassStatus[]} />}
    </div>
  )
}
