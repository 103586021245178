import styled from 'styled-components'
import { rgba } from 'polished'

export const Toolbar = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  column-gap: 1rem;
`

export const Calculation = styled.div`
  margin-top: 1rem;
  border-radius: 5px;
  background-color: white;
  padding: 2rem 1.5rem;
  box-shadow: 0 3px 6px ${({ theme }) => rgba(theme.palette.typo.lighter, 0.1)};
`

export const Header = styled.header`
  width: 100%;
  margin-bottom: 1.5rem;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  column-gap: 2rem;
`

export const PublisherItem = styled.div`
  position: relative;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 3px;
  margin-bottom: 0.5rem;

  transition: all 0.3s ease-in-out;

  &::before {
    content: '';
    display: block;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.palette.header.menu.highlight};

    transition: all 0.2s ease-in-out;
  }

  &:hover {
    background-color: white;
    border-radius: 0px;

    &::before {
      width: 2px;
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  column-gap: 2rem;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    column-gap: 2rem;
  }
`

export const Footer = styled.footer`
  padding-top: 2rem;
  display: flex;
  justify-content: flex-start;
  column-gap: 3rem;
`
