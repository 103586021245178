import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`

export const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  row-gap: 2rem;
  flex-wrap: wrap;

  > * {
    flex-shrink: 0;
  }
`

export const NavContainer = styled.div`
  display: flex;
`

export const Navlink = styled(NavLink)`
  width: fit-content;
  height: 2.625rem;

  display: flex;
  align-items: center;

  padding: 0px 1rem;

  background-color: ${({ theme }) => theme.palette.select.field.background};
  border: solid 1px;
  border-color: ${({ theme }) => theme.palette.select.field.border};

  &:first-child {
    border-radius: 8px 0px 0px 8px;
  }

  &:last-child {
    border-radius: 0px 8px 8px 0px;
  }

  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.select.field.label};

  &.active {
    color: ${({ theme }) => theme.palette.buttons.secondary.default.label};

    background-color: ${({ theme }) =>
      theme.palette.buttons.secondary.default.background};
  }
`
