import React, { ChangeEvent, useState } from 'react'
import { Button, Spinner, TextField, Typo } from '../../../../common/components'
import useExportPayment from '../../hooks/useExportPayment'

import { Overlay, Container, Header, Body, Footer } from './styles'

interface DialogProps {
  onCancel: () => void

  name: string
  access: string
  payment: string
  email: string

  totalAccess: number
  totalPayment: string

  totalValue: string

  username: string

  start: Date
  end: Date
}

export const MailDialog: React.FC<DialogProps> = ({
  onCancel,
  name,
  access,
  payment,
  email,
  totalAccess,
  totalPayment,
  start,
  end,
  username,
  totalValue
}) => {
  const [publisherContact, setPublisherContact] = useState<string>(email)

  const { loading, success, error, post } = useExportPayment()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPublisherContact(event.target.value)
  }

  const handleSubmit = () => {
    if (!publisherContact) return

    const payload = {
      name: name,
      email: publisherContact,
      start: start,
      end: end,
      username: username,
      access: access,
      payment: payment,
      totalAccess: totalAccess,
      totalPayment: totalPayment,
      totalValue: totalValue
    }

    post(payload)
  }

  return (
    <>
      <Overlay />
      <Container>
        <Header>
          <div>
            <Typo variant="label">Enviar para editora</Typo>
            <Typo variant="title">{name}</Typo>
          </div>
          <Button variant="modal" onClick={onCancel} />
        </Header>
        <Body>
          {loading && <Spinner size="full" />}
          {error && <Typo variant="error">{error}</Typo>}
          {!loading && !success && !error && (
            <TextField
              label="Email"
              name="publisher"
              value={publisherContact}
              onChange={handleChange}
              full
            />
          )}
          {!!success && <Typo variant="heading">{success}</Typo>}
        </Body>
        {!loading && !success && !error && (
          <Footer>
            <Button variant="primary" onClick={handleSubmit}>
              Enviar e-mail
            </Button>
          </Footer>
        )}
      </Container>
    </>
  )
}
