import React, { useEffect, useState } from 'react'
import { Navigate, Routes, Route } from 'react-router'

import { ProfileType } from '../../auth/context/AuthContext'
import useAuth from '../../auth/hooks/useAuth'
import { Typo } from '../../common/components'

import { Group, School, Teacher } from './pages'

import { Heading, NavContainer, Navlink } from './styles'

interface Priviledge {
  loading: boolean
  profile: ProfileType
}

const INITIAL_STATUS = {
  loading: true,
  profile: null
}

export const Reports: React.FC = () => {
  const [priviledge, setPriviledge] = useState<Priviledge>(INITIAL_STATUS)

  const { handleLoadPriviledge } = useAuth()

  useEffect(() => {
    const credentials = handleLoadPriviledge()
    setPriviledge({
      loading: false,
      profile: credentials
    })
  }, [])

  if (priviledge.loading) return null

  return (
    <>
      <Heading>
        <Typo variant="heading">Relatórios</Typo>
        <NavContainer>
          <Navlink to="por-professor" end>
            Por professor
          </Navlink>
          {(priviledge.profile === 'admin' ||
            priviledge.profile === 'group' ||
            priviledge.profile === 'school') && (
            <Navlink to="por-escola" end>
              Por escola
            </Navlink>
          )}
          {(priviledge.profile === 'admin' ||
            priviledge.profile === 'group') && (
            <Navlink to="por-taba" end>
              Por taba
            </Navlink>
          )}
        </NavContainer>
      </Heading>
      <Routes>
        <Route
          path="por-professor"
          element={<Teacher credentials={priviledge.profile} />}
        />
        {(priviledge.profile === 'admin' ||
          priviledge.profile === 'group' ||
          priviledge.profile === 'school') && (
          <Route path="por-escola" element={<School />} />
        )}

        {(priviledge.profile === 'admin' || priviledge.profile === 'group') && (
          <Route
            path="por-taba"
            element={<Group credentials={priviledge.profile} />}
          />
        )}

        <Route path="/" element={<Navigate to="por-professor" />} />
      </Routes>
    </>
  )
}
