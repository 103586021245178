import React, { useEffect, useState } from 'react'

import {
  Button,
  Datepicker,
  FilterBlock,
  Spinner,
  Typo
} from '../../../../common/components'
import { ActionBar, Body, Footer } from './styles'

import { Tabs } from '../../components'
import {
  AccessRankingReport,
  ClassStatusTabaReport,
  ReadingRankingReport,
  SchoolStatusReport
} from '../../components/Reports'

import useReportsByTaba, {
  RankingReport,
  SchoolStatus,
  ClassStatus
} from '../../hooks/useReportsByTaba'

import { ProfileType } from '../../../../auth/context/AuthContext'

interface Period {
  start: Date | null
  end: Date | null
}

const INITIAL_PERIOD = {
  start: null,
  end: null
}

type Reports =
  | 'school_status'
  | 'classroom_status'
  | 'book_access_ranking'
  | 'completed_book_ranking'

const TABS = [
  { label: 'Status das escolas', value: 'school_status' },
  { label: 'Status das turmas', value: 'classroom_status' },
  { label: 'Ranking de acesso aos livros', value: 'book_access_ranking' },
  { label: 'Ranking de livros finalizados', value: 'completed_book_ranking' }
]

interface ReportInterface {
  credentials: ProfileType
}

export const Group: React.FC<ReportInterface> = ({ credentials }) => {
  const [period, setPeriod] = useState<Period>(INITIAL_PERIOD)

  const [currentReport, setCurrentReport] = useState<Reports>('school_status')

  const {
    loading: generating,
    error: reportError,
    success: generated,
    schoolStatus,
    classStatus,
    rankingAccess,
    rankingCompleted,
    generate
  } = useReportsByTaba()

  const generateReport = () => {
    if (!period.start || !period.end) return
    generate({
      start: period.start,
      end: period.end,
      currentReport
    })
  }

  useEffect(() => {
    generateReport()
  }, [currentReport])

  const handleReportSelection = (value: string) => {
    setCurrentReport(value as Reports)
  }

  const handleChangeDate = (date: [Date, Date]) => {
    setPeriod({
      start: date[0],
      end: date[1]
    })
  }

  const handleSubmit = () => generateReport()

  const handleResetForm = () => {
    setPeriod(INITIAL_PERIOD)
  }

  const canSubmit = !!period.start && !!period.end

  return (
    <div>
      <FilterBlock heading="Por taba">
        <Body>
          <ActionBar>
            <Datepicker
              label="Período"
              start={period.start}
              finish={period.end}
              onChange={handleChangeDate}
            />
          </ActionBar>
          <Footer>
            <Button onClick={handleResetForm}>Limpar campos</Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={!canSubmit}
            >
              Gerar relatórios
            </Button>
          </Footer>
        </Body>
      </FilterBlock>

      {generating && <Spinner size="full" />}
      {reportError && (
        <Typo variant="error">Ocorreu um erro ao gerar o relatório</Typo>
      )}
      {generated && (
        <>
          <Tabs
            activeTab={currentReport}
            onSelectTab={handleReportSelection}
            tabs={TABS}
          />
          {currentReport === 'school_status' && (
            <SchoolStatusReport data={schoolStatus as SchoolStatus[]} />
          )}

          {currentReport === 'classroom_status' && (
            <ClassStatusTabaReport data={classStatus as ClassStatus[]} />
          )}

          {currentReport === 'book_access_ranking' && (
            <AccessRankingReport data={rankingAccess as RankingReport[]} />
          )}

          {currentReport === 'completed_book_ranking' && (
            <ReadingRankingReport data={rankingCompleted as RankingReport[]} />
          )}
        </>
      )}
    </div>
  )
}
