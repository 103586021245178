import React from 'react'
import { useLocation, Navigate } from 'react-router'
import useAuth from '../../hooks/useAuth'

function RequireAuth({ children }: { children: JSX.Element }) {
  const { isAuthenticated, loading } = useAuth()
  const location = useLocation()

  if (!isAuthenticated && !loading) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  return children
}

export default RequireAuth
