import React, { useEffect, useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Header } from '../../../layout'

import Payment from '../../../modules/payment/pages/Payment/Payment'
import { Reports } from '../../../modules/Reports'
import { Visualization } from '../../../modules/visualization/pages'
import { ProfileType } from '../../context/AuthContext'
import useAuth from '../../hooks/useAuth'

import { Body, Footer, PageContainer } from './styles'

export const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const { handleLoadPriviledge } = useAuth()
  const [privilege, setPrivilege] = useState<ProfileType>(null)

  useEffect(() => {
    const credentials = handleLoadPriviledge()
    setPrivilege(credentials)
    setLoading(false)
  }, [])

  if (loading) return null

  if (privilege === 'admin') {
    return (
      <PageContainer>
        <Header />
        <Body>
          <Routes>
            <Route path="visualizacoes" element={<Visualization />} />
            <Route path="repasse" element={<Payment />} />
            <Route path="relatorios/*" element={<Reports />} />
            <Route path="/" element={<Navigate to="visualizacoes" />} />
          </Routes>
          <Footer />
        </Body>
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <Header />
      <Body>
        <Routes>
          <Route path="relatorios/*" element={<Reports />} />
          <Route path="/" element={<Navigate to="relatorios" />} />
        </Routes>
        <Footer />
      </Body>
    </PageContainer>
  )
}
