import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 330px;
  height: 2.625rem;
  position: relative;
`

export const Field = styled.input`
  appearance: none;
  border: none;

  padding: 0px 1rem 0px 3rem;
  width: 100%;
  height: 2.625rem;

  border: solid 1px ${({ theme }) => theme.palette.select.field.border};
  border-radius: 9px;

  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.typo.main};

  &::placeholder {
    color: #cacbe6;
  }
`
